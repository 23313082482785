import {Avatar, Box, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import * as React from "react";

const GOALS = ["GP", "GC", "GPHT", "GCHT"];
const POINTS = ["PTS", "P"]
const AVG_GOALS = ["MGP", "MGC", "MGPHT", "MGCHT"];
const MATCHES = ["V", "E", "D", "J"]
const MIN = 0
let columnMaxValues = 100;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const TableClassification = (props) => {
    const {data, column, title, defaultSort, zones} = props;
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(defaultSort);

    columnMaxValues = column.map((item) => {
        const value = item.value
        const heading = item.heading
        const maxMaxValues = Math.max(...data.map(item => item[value]));
        const maxValues = {};
        maxValues[heading] = maxMaxValues
        return maxValues
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    if (data.length === 0) {
        return <TableContainer></TableContainer>
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableHead key="Titulo">
                    <TableRow>
                        <TableCell
                            className="table-title"
                            sx={{fontSize: "0.8rem", borderBottom: "black"}}
                            colSpan={column.length + 1}
                            align={"center"}
                        >
                            {title}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableHeadItem column={column}
                               order={order}
                               orderBy={orderBy}
                               onRequestSort={handleRequestSort}
                               rowCount={data.length}/>
                <TableBody>
                    {visibleRows.map((item, index) => (
                        <TableRowMatch key={index} item={item} column={column} rowIndex={index}/>
                    ))}
                </TableBody>
            </Table>
            {zones && zones.map((zone) => {
                if (zone.name) {
                    return (
                        <footer className="table-footer">
                            <Typography
                                className={handlePositionStyle(zone)}> </Typography>
                            <Typography>{zone.name}</Typography>
                        </footer>
                    )
                }
                return null
            })}
        </TableContainer>
    );
};

const TableHeadItem = (props) => {
    const {order, orderBy, column, onRequestSort} = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow className="table-title">
                <TableCell
                    sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "1px solid black"}}
                    className="enhaced-table-title"
                    align={"center"}>
                    <Tooltip followCursor={true} title="Rank" placement="top-start">
                        <Typography className="table-header">
                            RK
                        </Typography>
                    </Tooltip>
                </TableCell>
                {column.map((item, index) => (
                    <TableCell
                        sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "1px solid black"}}
                        align={"center"}
                        key={item.id + item.index}
                        sortDirection={orderBy === item.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === item.id}
                            direction={orderBy === item.id ? order : 'asc'}
                            onClick={createSortHandler(item.id)}
                            className="enhaced-table-title"
                            hideSortIcon
                        >
                            {orderBy === item.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                            <Tooltip followCursor={true} title={item.alt} placement="top-start">
                                <Typography className="table-header">
                                    {item.heading}
                                </Typography>
                            </Tooltip>
                        </TableSortLabel>
                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    )
};

function handleStyle(value, columnItem) {
    // if (columnItem.heading === "Mandante") {
    //     return "yellow";
    // }
    //
    // if (columnItem.heading === "Visitante") {
    //     return "blue";
    // }

    if (AVG_GOALS.includes(columnItem.heading)) {
        if (value > 20) {
            return "good";
        } else if (value > 2) {
            return "neutral";
        } else {
            return "bad";
        }
    }
}

function handlePositionStyle(zone) {
    if (zone) {
        if (zone.name) {
            if (zone.name.includes('Relegation')) {
                return 'relegation-round-rank'
            }

            if (zone.number === 1) {
                return 'championship-round-rank-1'
            }

            if (zone.number === 2) {
                return 'championship-round-rank-2'
            }

            if (zone.number === 3) {
                return 'championship-round-rank-3'
            }

            if (zone.number === 4) {
                return 'championship-round-rank-4'
            }

            if (zone.number === 5) {
                return 'championship-round-rank-5'
            }

            if (zone.number === 6) {
                return 'championship-round-rank-6'
            }
        }
    }
    return 'normal-round-rank'
}

const normalise = (value, heading) => {
    const MAX = (columnMaxValues.find(item => heading in item) || {})[heading]
    return (value - MIN) * 100 / (MAX - MIN);
}

function addMask(value, columnItem) {
    if (value === null) {
        return value
    }

    if (columnItem.value.includes('Percentage')) {
        return value.toFixed(2) + '%'
    }

    if (GOALS.includes(columnItem.heading) || MATCHES.includes(columnItem.heading) || POINTS.includes(columnItem.heading)) {
        return value
    }

    return typeof value === 'number' ? value.toFixed(2) : value
}

function TableValue(props) {
    const {column, value, image} = props;
    if (GOALS.includes(column.heading)) {
        return <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className="championship-progress-bar"
                                color={column.heading.includes("GP") ? "success" : "error"} variant="determinate"
                                value={normalise(value, column.heading)}/>
            </Box>
            <Typography className="championship-progress-bar-text" variant="body2" color="textSecondary">
                {value}
            </Typography>
        </Box>
    }

    if (column.value === "cleanName") {
        return <Typography variant="h6" component="div"
                           sx={{
                               flex: '1 1 100%', width: 'max-content',
                               fontSize: "0.8rem", padding: "2px",
                           }}>
            <Avatar className="team-flag" src={`${image}`} variant="square"/>
            {value}
        </Typography>
    }
    return addMask(value, column)
}

const TableRowMatch = ({item, column, rowIndex}) => {
    return (
        <TableRow className="line-color" hover>
            <TableCell
                className={"poisson-status championship-table-cell col"}
                // data-status={handleStyle(1, "Contagem")}
                sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "black"}}
                key={rowIndex}
                align={"center"}
            >
                {item.position ?
                    <Typography className={handlePositionStyle(item.zone)}>{item.position}</Typography> :
                    <Typography className={handlePositionStyle(item.zone)}>{rowIndex + 1}</Typography>}
            </TableCell>
            {column.map((columnItem, index) => {
                return (
                    <TableCell
                        className={"poisson-status championship-table-cell col"}
                        data-status={handleStyle(item[`${columnItem.value}`], columnItem)}
                        sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "black"}}
                        key={index}
                        align={"center"}
                    >
                        <TableValue column={columnItem} value={item[`${columnItem.value}`]} image={item.image}/>
                    </TableCell>
                );
            })}
        </TableRow>
    )
};
