import {DataGrid} from "@mui/x-data-grid";
import * as React from "react";
import PropTypes from "prop-types";

export const MarketOverTable = (props) => {
    const overColumns = [
        {
            field: "id", headerName: "Linha de Gol", width: 100, sortable: false,
            renderCell: (params) => (params.value.replace('over_', 'Over ').replace(/\b(\d{1})(\d{1})\b/g, '$1.$2'))
        },
        {field: "over", headerName: "Over", width: 75, sortable: false, renderCell: (params) => (params.value + "%")},
        {field: "fair_over", headerName: "Fair", width: 75, sortable: false,},
        {
            field: "odd_over", headerName: "Odd", width: 75, editable: true, sortable: false,
            valueFormatter: (params) => {
                let formattedNumber = new Intl.NumberFormat('en-US').format(params.value);
                formattedNumber = formattedNumber.replace(',', '.')
                // Substituir ',' por '.' se Intl.NumberFormat não formatar corretamente para suas necessidades.
                return formattedNumber.replace(/,/g, '.');
            },
        },
        {
            field: "valor_over",
            headerName: "Valor",
            width: 75,
            sortable: false,
            cellClassName: (params) => params.value > 10 ? "cell-green" : params.value > 0 ? 'cell-yellow' : 'cell-red',
            renderCell: (params) => (params.value + "%")
        },
    ]

    const {rows} = props

    return (
        <div className="datagrid-container">
            <DataGrid
                disableColumnMenu
                style={{width: '400px'}}
                className="overUnder"
                getRowId={(row) => row.id}
                rows={rows}
                columns={overColumns}
                pageSize={25} s
                autoHeight={true}
                rowHeight={25}
                headerHeight={25}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                onCellEditCommit={props.onCellEditCommit}
            />
        </div>
    );
}

MarketOverTable.propTypes = {
    rows: PropTypes.array.isRequired,
    onCellEditCommit: PropTypes.func.isRequired,
}