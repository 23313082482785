import * as React from "react";
import {AppBar, Avatar, Box, Button, Container, Grid, IconButton, Menu, MenuItem, Toolbar, Typography,} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAuthorization} from "../../actions";
import initialState from "../../reducers";
import dayjs from "dayjs";

const pages = [
    {title: "Perfil", route: "/profile", roles: ['user']},
    // {title: "Trocar Senha", route: "/password", roles: ['user']},
    {title: "Usuários", route: "users", roles: ['admin']},
    {title: "Campeonato", route: "/championship", roles: ['admin']},
    {title: "Jogo", route: "/match", roles: ['user']},
    {title: "Assinatura", route: "/plan", roles: ['user']},
    {title: "Termo de Uso", route: "/term", roles: []},
    {title: "sair", route: "/", roles: ['user']},
    // {title: "Sobre", route: "/about", roles: []},
];
// const settings = [
//     {title: "Perfil", route: "/profile", roles: ['user']},
//     {title: "Termo de Uso", route: "/term", roles: []},
//     // {title: "Conta", route: "/", roles: ['user']},
//     {title: "Trocar Senha", route: "/password", roles: ['user']},
//     {title: "Assinatura", route: "/plan", roles: ['user']},
//     {title: "sair", route: "/", roles: ['user']},
// ];

const ResponsiveAppBar = () => {
    const dispatch = useDispatch();
    const {authorization, user} = useSelector((state) => state.userReducers);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);

    const signOut = () => {
        localStorage.removeItem("token");
        dispatch(setAuthorization({
            access_token: "",
            token_type: "",
            roles: [],
        }))
        dispatch({
            user: initialState.user
        })
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    const handleButton = (value) => {
        if (value === 'sair' || value === 'logout') {
            signOut()
        }
    };

    const handleValidade = (value) => {
        return dayjs(value).format('DD/MM/YYYY')
    }

    const isAuthorized = (value) => {
        return ((value.length === 0) || value.some(i => authorization.roles.includes(i)))
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Avatar alt="Simple Sports Football & Soccer Badge Logo"
                            src={require('../../static/soccer_badge.png')}
                            sx={{width: 112, height: 112}} component={Link} to="/"/>
                    {/*<AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />*/}
                    <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: "block", md: "none"},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.title} onClick={handleCloseNavMenu} className={'page-menu-item'}
                                          hide={(!isAuthorized(page.roles)).toString()}>
                                    <Button component={Link} onClick={() => {
                                        handleButton(page.title)
                                    }} to={page.route}>
                                        {page.title}
                                    </Button>
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box>
                    <Typography
                        component={Link}
                        variant="body1"
                        noWrap
                        to="/"
                        sx={{
                            mr: 2,
                            display: {xs: "flex", md: "none"},
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {user.nome}
                    </Typography>
                    <Box sx={{flexGrow: 3, display: {xs: "none", md: "flex"}}}>
                        {pages.map((page) => (
                            <Button
                                component={Link}
                                key={page.title}
                                onClick={() => {
                                    handleButton(page.title)
                                }}
                                to={page.route}
                                sx={{my: 2, color: "white", display: "block"}} className={'page-menu-item'}
                                hide={(!isAuthorized(page.roles)).toString()}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>
                    <Box hidden={!user.nome} sx={{flexGrow: 1}} className={'page-menu-item'}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        // display: {xs: "flex", md: "none"},
                                        // flexGrow: 1,
                                        // fontFamily: "monospace",
                                        textAlign: "right",
                                        fontWeight: 500,
                                        // letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    Nome:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        textAlign: "left",
                                        // display: {xs: "flex", md: "none"},
                                        // flexGrow: 1,
                                        // fontFamily: "monospace",
                                        // fontWeight: 500,
                                        // letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    {user.nome}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        textAlign: "right",
                                        // display: {xs: "flex", md: "none"},
                                        // flexGrow: 1,
                                        // fontFamily: "monospace",
                                        fontWeight: 500,
                                        // letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    Validade Assinatura:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        textAlign: "left",
                                        // display: {xs: "flex", md: "none"},
                                        // flexGrow: 1,
                                        // fontFamily: "monospace",
                                        // fontWeight: 500,
                                        // letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    {handleValidade(user.validade)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/*<Box sx={{flexGrow: 0}} className={'page-menu-item'}*/}
                    {/*     hide={(!isAuthorized(['user'])).toString()}>*/}
                    {/*    <Tooltip title="Open settings">*/}
                    {/*        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>*/}
                    {/*            <Avatar alt="Remy Sharp" src="/static/soccer_badge.png"/>*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*    <Menu*/}
                    {/*        sx={{mt: "45px"}}*/}
                    {/*        id="menu-user-appbar"*/}
                    {/*        anchorEl={anchorElUser}*/}
                    {/*        anchorOrigin={{*/}
                    {/*            vertical: "top",*/}
                    {/*            horizontal: "right",*/}
                    {/*        }}*/}
                    {/*        keepMounted*/}
                    {/*        transformOrigin={{*/}
                    {/*            vertical: "top",*/}
                    {/*            horizontal: "right",*/}
                    {/*        }}*/}
                    {/*        open={Boolean(anchorElUser)}*/}
                    {/*        onClose={handleCloseUserMenu}*/}
                    {/*    >*/}
                    {/*        {settings.map((setting) => (*/}
                    {/*            <MenuItem key={setting.title} onClick={handleCloseUserMenu} className={'page-menu-item'}*/}
                    {/*                      hide={(!isAuthorized(setting.roles)).toString()}>*/}
                    {/*                <Button component={Link} onClick={() => {*/}
                    {/*                    handleButton(setting.title)*/}
                    {/*                }} to={setting.route}>*/}
                    {/*                    {setting.title}*/}
                    {/*                </Button>*/}
                    {/*            </MenuItem>*/}
                    {/*        ))}*/}
                    {/*    </Menu>*/}
                    {/*</Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
