import React, {useEffect} from "react";
import {Route, Routes as RouterSwitch} from "react-router-dom";
import ResponsiveAppBar from "./features/navbar/ResponsiveAppBar";
import Login from "./views/login/Login";
import Championship from "./views/championship/Championship";
import Match from "./views/match/Match";
import {Chip, CssBaseline} from "@mui/material";
import {RequireToken} from "./Auth";
import Profile from "./views/profile/Profile";
import Register from "./views/register/Register";
import Forget from "./views/forget/Forget";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import {useDispatch, useSelector} from "react-redux";
import Users from "./views/users/Users";
import Role from "./views/users/Role";
import Pwd from "./views/password/password";
import Term from "./views/term/Term";
import Accept from "./views/term/Accept";
import {LocaleProvider} from "./locale";
import {getChampionshipsCarroucel} from "./actions";
import {CarouselPrematch} from "./components/common/CarouselPrematch";
import Subscription from "./views/subscription/Subscription";

function App() {
    const {authorization, championshipCarroucel} = useSelector((state) => state.userReducers);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!championshipCarroucel || championshipCarroucel.length === 0) {
            dispatch(getChampionshipsCarroucel()).then()
        }
    }, [dispatch, championshipCarroucel]);

    return (
        <LocaleProvider>
            <div className="App">
                <ToastContainer
                    position="top-right"
                    autoClose={30000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <CssBaseline/>
                <ResponsiveAppBar/>
                <div className="container">
                    <RouterSwitch>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/profile"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["user"]}><Profile/></RequireToken>}/>
                        <Route path="/championship"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["admin"]}><Championship/></RequireToken>}/>
                        <Route path="/match"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["user"]}><Match/></RequireToken>}/>
                        {/*<Route path="/about" element={<About/>}/>*/}
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/forget" element={<Forget/>}/>
                        <Route path="/users"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["admin"]}><Users/></RequireToken>}/>
                        <Route path="/role"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["admin"]}><Role/></RequireToken>}/>
                        <Route path="/password"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["user"]}><Pwd/></RequireToken>}/>
                        <Route path="/term"
                               element={<Term/>}/>
                        <Route path="/accept"
                               element={<RequireToken role={authorization.roles}
                                                      roles={["user"]}><Accept/></RequireToken>}/>
                        <Route path="/plan"
                               element={<Subscription/>}/>
                    </RouterSwitch>
                </div>
                <div>
                    <CarouselPrematch items={championshipCarroucel} indicators={true} navButtonsAlwaysVisible={true}
                                      className="carousel">

                    </CarouselPrematch>
                </div>
                <Chip className="copyright" color="success"
                      label='Copyright ©2024 - MUSSUMAGO DIGITAL - TODOS OS DIREITOS RESERVADOS'/>
            </div>
        </LocaleProvider>
    );
}

export default App;
