import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Avatar, Tooltip} from "@mui/material";

function addMask(value, columnItem) {
    if (value === undefined || value === null) {
        return value
    }

    if (columnItem === 'prob') {
        return value.toFixed(2) + '%'
    }

    if (columnItem === 'int') {
        return value
    }

    return typeof value === 'number' ? value.toFixed(2) : value
}

function handleStyle(value, columnItem) {
    if (columnItem === "odd") {
        return "blue";
    }

    if (columnItem === "goals") {
        return "yellow";
    }

    if (columnItem === "prob") {
        if (value > 30.0) {
            return "level10";
        } else if (value > 7.0) {
            return "level9";
        } else if (value > 3.4) {
            return "level8";
        } else if (value > 2.6) {
            return "level7";
        } else if (value > 1.9) {
            return "level6";
        } else if (value > 1.3) {
            return "level5";
        } else if (value > 0.8) {
            return "level4";
        } else if (value > 0.4) {
            return "level3";
        } else if (value > 0.1) {
            return "level2";
        } else {
            return "level1";
        }
    }
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'goals') {
        if (['GLE', 'GLM', 'GLV'].includes(a['goals']) && ['GLE', 'GLM', 'GLV'].includes(b['goals'])) {
            return 0
        }
        if (b['id'] < a['id']) {
            return -1;
        }
        if (b['id'] > a['id']) {
            return 1;
        }
        return 0;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort, headCells} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="table-title">
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className="enhaced-table-title"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const {title, pflag, vflag, title_alt} = props;
    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                fontSize: "0.8rem", padding: "2px",
            }}
            className="enhaced-poisson-toolbar"
            colSpan="3"
            align={"center"}
        >
            <Tooltip followCursor={true} title={title_alt} placement="top">
                <Typography
                    sx={{
                        flex: '1 1 100%',
                        fontSize: "0.8rem", padding: "2px",
                    }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    className="table-title enhaced-table-title"
                >
                    {pflag && <Avatar className="team-flag" src={`${pflag}`} variant="square"/>}
                    {title}
                    {vflag && <Avatar className="team-flag flag-visitante" src={`${vflag}`} variant="square"/>}
                </Typography>
            </Tooltip>

            {/*<Tooltip title="Filter list">*/}
            {/*  <IconButton sx={{padding: "2px"}}>*/}
            {/*    <FilterListIcon/>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    pflag: PropTypes.string,
    vflag: PropTypes.string,
};

export const EnhancedPoissonTable = ({rows, total, headCells, title, title_alt, pflag, vflag}) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows],
    );

    if (rows.length === 0) {
        return (<Box sx={{width: '100%', margin: '0px 0px 10px'}}></Box>)
    }

    return (
        <Box sx={{width: '100%', margin: '0px 0px 10px'}}>
            <Paper sx={{mb: 2}} className="enhaced-poisson-toolbar-paper">
                <EnhancedTableToolbar title={title} title_alt={title_alt} pflag={pflag} vflag={vflag}/>
                <TableContainer>
                    <Table
                        sx={{margin: '0px'}}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        className="enhaced-poisson-table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                        className="match-table-row"
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none" align={"center"}
                                            className={"poisson-status"}
                                            sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                                            data-status={handleStyle(row.goals, "goals")}
                                        >
                                            {row.goals}
                                        </TableCell>
                                        {row.jogos && <TableCell align={"center"}
                                                                 className={"poisson-status"}
                                                                 sx={{
                                                                     fontSize: "0.8rem",
                                                                     border: "none",
                                                                     padding: "2px"
                                                                 }}
                                                                 data-status={handleStyle(row.jogos, "int")}>{addMask(row.jogos, "int")}</TableCell>
                                        }
                                        <TableCell align={"center"}
                                                   className={"poisson-status"}
                                                   sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                                                   data-status={handleStyle(row.prob, "prob")}>{addMask(row.prob, "prob")}</TableCell>
                                        <TableCell align={"center"}
                                                   className={"poisson-status"}
                                                   sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                                                   data-status={handleStyle(row.odd, "odd")}>{addMask(row.odd, "odd")}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (33) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                        {total && Object.keys(total).length > 0 && (
                            <TableBody>
                                <TableRow className={"total-jogos"}>
                                    <TableCell
                                        component="th"
                                        id="total"
                                        scope="row"
                                        padding="none" align={"center"}
                                        className={"poisson-status"}
                                        sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                                        data-status={handleStyle("total", "goals")}
                                    >
                                        {"Total"}
                                    </TableCell>
                                    <TableCell align={"center"}
                                               className={"poisson-status"}
                                               sx={{
                                                   fontSize: "0.8rem",
                                                   border: "none",
                                                   padding: "2px"
                                               }}
                                               data-status={handleStyle(total.qtd, "int")}>{addMask(total.qtd, "int")}</TableCell>
                                    <TableCell align={"center"}
                                               className={"poisson-status"}
                                               sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                                               data-status={handleStyle(total.prob, "prob")}>{addMask(total.prob, "prob")}</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}