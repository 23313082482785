import {Backdrop, Box, Button, CircularProgress, Container, Grid, TextField, Typography} from "@mui/material";
import React from "react";
import {toast} from "react-toastify";
import {recoverPassword} from "../../actions";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";

function Forget() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [forgetLoading, setForgetLoading] = React.useState(false);
    const handleSubmit = async (event) => {
        setForgetLoading(true)
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        let object = {};
        data.forEach((value, key) => object[key] = value);

        await dispatch(recoverPassword(data.get("email"))).then(payload => {
        }).catch(error => {
            toast.error(error.detail)
            setForgetLoading(false)
            navigate("/login")
            throw error
        })
        toast.info("Email enviado para o usuário!")
        setForgetLoading(false)
        navigate("/login")
    };

    return (
        <Container component="main" maxWidth="md">
            <Backdrop open={forgetLoading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box className="login-box">
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5"
                                        style={{fontWeight: '500', marginBottom: '1rem'}}>
                                Perdi minha senha
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Endereço de e-mail"
                                autoComplete="email"
                                name="email"
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>Enviar</Button>
                </Box>
            </Box>
        </Container>
    )
};

export default Forget;