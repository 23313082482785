import React from 'react';
import {SubscriptionCard} from "../../components/common/SubscriptionCard";

export const Annual = (props) => {
    const {sx, price, monthly, discount} = props

    return (
        <SubscriptionCard sx={{...sx}} price={price} monthly={monthly} discount={discount} discountColor="error"
                          title="Anual"/>
    )
}