import React from 'react';
import {SubscriptionCard} from "../../components/common/SubscriptionCard";

export const Semiannual = (props) => {
    const {sx, price, monthly, discount} = props

    return (
        <SubscriptionCard sx={{...sx}} price={price} monthly={monthly} discount={discount} discountColor="warning"
                          title="Semestral"/>
    )
}