import React from "react";
import {Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {changePassword} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";

function Pwd() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {user, authorization} = useSelector(
        state => state.userReducers
    );
    const handleChangePassword = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        if (data.get('new_password') !== data.get('repetir password')) {
            toast('As novas senhas devem ser iguais')
            return
        }
        let passwordChanged = await dispatch(changePassword(user.id, data, authorization.access_token))
            .catch(error => {
                toast.error(error.detail)
                navigate("/password")
            })
        if (passwordChanged) {
            toast.info("Senha trocada com sucesso!")
            navigate("/profile")
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Box className="login-box">
                <Box component="form" onSubmit={handleChangePassword}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5"
                                        style={{fontWeight: '500', marginBottom: '1rem'}}>
                                Trocar Senha
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                id="current_password"
                                label="Senha Atual"
                                autoComplete="current-password"
                                name="password"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                id="new_password"
                                label="Nova Senha"
                                autoComplete="new-password"
                                name="new_password"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                id="password2"
                                label="Repita Nova Senha"
                                autoComplete="new-password"
                                name="repetir password"
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>Trocar Senha</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default Pwd;
