import {useLocation, Navigate} from "react-router-dom"

export const setToken = (token) => {
  localStorage.setItem("token", token)// make up your own token
}

export const fetchToken = (token) => {

  return localStorage.getItem("token")
}

export function RequireToken({children, role, roles = []}) {

  let auth = fetchToken()
  let location = useLocation()

  const found = roles.some(i => role.includes(i))

  if (!auth || !found) {

    return <Navigate to='/' state={{from: location}}/>;
  }

  return children;
}