import React, {createContext, useContext} from "react";

// Crie um Context para o Locale
const LocaleContext = createContext('en-US');

export function useLocale() {
    return useContext(LocaleContext);
}

export function LocaleProvider({children}) {
    const locale = 'en-US';  // Ajuste para o locale desejado

    return (
        <LocaleContext.Provider value={locale}>
            {children}
        </LocaleContext.Provider>
    );
}