import {
    DELETE_SEASON,
    GET_ADDED_CHAMPIONSHIPS,
    GET_ALL_ROLES,
    GET_CHAMPIONSHIPS,
    GET_CHAMPIONSHIPS_CARROUCEL,
    GET_CIDADE,
    GET_EDITED_MARKET,
    GET_ESTADO,
    GET_MARKET,
    GET_MATCH_POISSON,
    GET_PRINCIPAL_DATA,
    GET_RECOVER_PASSWORD,
    GET_SCORE_FREQUENCY,
    GET_SEASON,
    GET_SEASON_TEAMS,
    GET_SEASONS,
    GET_TABLES,
    GET_USER,
    GET_USER_ROLES,
    GET_USERS,
    GET_VISITOR_DATA,
    POST_CHANGE_PASSWORD,
    POST_LOGIN,
    POST_SEASON,
    POST_USER,
    RESET_HANDCAP_MARKET,
    SET_AUTHORIZATION,
    SET_CHAMPIONSHIP,
    SET_CIDADE,
    SET_EDITED_USER_BAIRRO,
    SET_EDITED_USER_CEP,
    SET_EDITED_USER_COMPLEMENTO,
    SET_EDITED_USER_NUMERO,
    SET_EDITED_USER_ROLE_ID,
    SET_EDITED_USER_RUA,
    SET_EDITED_USER_TELEFONE,
    SET_ESTADO,
    SET_HANDCAP_MARKET,
    SET_ODD_MANDANTE,
    SET_ODD_VISITANTE,
    SET_OVER_05,
    SET_OVER_15,
    SET_OVER_25,
    SET_OVER_35,
    SET_OVER_45,
    SET_OVER_55,
    SET_OVER_65,
    SET_OVER_MARKET,
    SET_PRINCIPAL,
    SET_PRINCIPAL_GAMES,
    SET_PRINCIPAL_GOALS_CONCEDED,
    SET_PRINCIPAL_GOALS_SCORED,
    SET_PRINCIPAL_SEASON_GOALS_MEAN,
    SET_RESIDENTE_EXTERIOR,
    SET_SEASON,
    SET_TIPITICACAO_JOGO,
    SET_USERNAME,
    SET_VISITOR,
    SET_VISITOR_GAMES,
    SET_VISITOR_GOALS_CONCEDED,
    SET_VISITOR_GOALS_SCORED,
    SET_VISITOR_SEASON_GOALS_MEAN,
} from "../actions";

const initialState = {
    username: "",
    user: {
        id: "",
        usuario_id: "",
        nome: "",
        cpf: "",
        email: "",
        disabled: "",
        termo: false,
        bairro: "",
        cep: "",
        cidade: 0,
        estado: 0,
        rua: "",
        complemento: "",
        numero: "",
        residenteExterior: false,
        validade: ""
    },
    users: [],
    authorization: {
        access_token: "",
        token_type: "",
        roles: [],
    },
    edited_user_roles: [],
    edited_user_roles_id: "",
    estado: "",
    estados: [],
    cidade: "",
    cidades: [],
    oddMandante: 1.00,
    oddVisitante: 1.00,
    tipificacaoJogo: 'indefinido',
    championshipsCarroucel: [],
    championships: [],
    championship: "",
    addedChampionships: [],
    season: "",
    seasonStatistics: {
        matchesCompleted: 0,
        totalMatches: 0,
        game_week: 0,
        total_game_week: 0,
    },
    tables: [],
    seasonScoreFrequency: {
        "sfm": [],
        "fm": [],
        "mfm": [],
        "eq": [],
        "mfv": [],
        "fv": [],
        "sfv": [],
        "total": [],
        "qtd_jogos": {
            "sfm": {},
            "fm": {},
            "mfm": {},
            "eq": {},
            "mfv": {},
            "fv": {},
            "sfv": {},
            "total": {},
        }
    },
    seasons: [],
    seasonTeams: [],
    poisson_ft: [],
    poisson_ht: [],
    chance_ft: [],
    chance_ht: [],
    principal: "",
    recover_password: {
        detail: ""
    },
    change_password: {
        message: ""
    },
    market: {
        poisson_ft: [],
        btts_ft: [],
        match_odds_ft: [],
        over_under_ft: [],
        handcap_asiatico: [],
        chance_gols: {
            mandante: 0.0,
            visitante: 0.0,
            total: 0.0,
        }
    },
    principalData: {
        name: "",
        image: "",
        jogos: 0,
        forca_ofensiva: 0.0,
        forca_defensiva: 0.0,
        gols_pro_ft: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_pro_ht: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_contra_ft: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_contra_ht: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        btts_ft: {
            casa: 0.0,
            ranking: 0,
            media_geral: 0.0,
            linear: 0.0,
            ranking_linear: 0,
        },
        chutes_a_gol: {
            chutes: 0,
            ranking: 0,
            media_chutes: 0.0,
            ranking_media: 0,
            liga: 0.0,
            media_liga: 0.0,
        },
        chutes_no_gol: {
            chutes: 0,
            ranking: 0,
            media_chutes: 0.0,
            ranking_media: 0,
            liga: 0.0,
            media_liga: 0.0,
        },
        btts_ht: {
            casa: 0.0,
            ranking: 0,
            media_geral: 0.0,
        },
        cantos_ht: {
            cantos_pro: 0,
            cantos_contra: 0,
            media_cantos_pro: 0.0,
            media_cantos_contra: 0.0,
            media_cantos_total: 0.0,
            media_cantos_partida: 0.0,
        },
        cantos_ft: {
            cantos_pro: 0,
            cantos_contra: 0,
            media_cantos_pro: 0.0,
            media_cantos_contra: 0.0,
            media_cantos_total: 0.0,
            media_cantos_partida: 0.0,
        },
        minutagem_ht: {
            "0-15": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "16-30": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "31-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-60": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "61-75": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "76-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "0-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
        },
        minutagem_ft: {
            "0-15": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "16-30": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "31-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-60": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "61-75": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "76-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "0-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
        },
        confrontos: [],
        tipificacoes: {
            seasons_1: [],
            seasons_4: [],
            seasons_1_total: [],
            seasons_4_total: []
        }
    },
    visitor: "",
    visitorData: {
        name: "",
        image: "",
        jogos: 0,
        forca_ofensiva: 0.0,
        forca_defensiva: 0.0,
        gols_pro_ft: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_pro_ht: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_contra_ft: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        gols_contra_ht: {
            qtd: 0,
            media_time: 0.0,
            ranking_time: 0,
            media_geral: 0.0,
            var: 0.0,
            media_var_time: 0,
            media_var_geral: 0.0,
        },
        btts_ft: {
            casa: 0.0,
            ranking: 0,
            media_geral: 0.0,
            linear: 0.0,
            ranking_linear: 0,
        },
        chutes_a_gol: {
            chutes: 0,
            ranking: 0,
            media_chutes: 0.0,
            ranking_media: 0,
            liga: 0.0,
            media_liga: 0.0,
        },
        chutes_no_gol: {
            chutes: 0,
            ranking: 0,
            media_chutes: 0.0,
            ranking_media: 0,
            liga: 0.0,
            media_liga: 0.0,
        },
        btts_ht: {
            casa: 0.0,
            ranking: 0,
            media_geral: 0.0,
        },
        cantos_ht: {
            cantos_pro: 0,
            cantos_contra: 0,
            media_cantos_pro: 0.0,
            media_cantos_contra: 0.0,
            media_cantos_total: 0.0,
            media_cantos_partida: 0.0,
        },
        cantos_ft: {
            cantos_pro: 0,
            cantos_contra: 0,
            media_cantos_pro: 0.0,
            media_cantos_contra: 0.0,
            media_cantos_total: 0.0,
            media_cantos_partida: 0.0,
        },
        minutagem_ht: {
            "0-15": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "16-30": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "31-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-60": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "61-75": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "76-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "0-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
        },
        minutagem_ft: {
            "0-15": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "16-30": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "31-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-60": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "61-75": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "76-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "0-45": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
            "46-90": {marcados: 0, marcados_chance: 0, sofridos: 0, sofridos_chance: 0},
        },
        confrontos: [],
        tipificacoes: {
            seasons_1: [],
            seasons_4: [],
            seasons_1_total: [],
            seasons_4_total: []
        }
    },
};

function userReducers(state = initialState, action) {
    switch (action.type) {
        case GET_CHAMPIONSHIPS_CARROUCEL:
            return {...state, championshipCarroucel: action.payload};
        case GET_CHAMPIONSHIPS:
            return {...state, championships: action.payload};
        case SET_CHAMPIONSHIP:
            return {
                ...state,
                championship: action.payload,
                principalData: initialState.principalData,
                visitorData: initialState.visitorData,
                poisson_ft: initialState.poisson_ft,
                poisson_ht: initialState.poisson_ht,
                chance_ft: initialState.chance_ft,
                chance_ht: initialState.chance_ht
            };
        case GET_SEASON_TEAMS:
            return {
                ...state,
                seasonTeams: action.payload.sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    const nameA = a.cleanName.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.cleanName.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }),
            };
        case SET_PRINCIPAL:
            return {...state, principal: action.payload};
        case GET_PRINCIPAL_DATA:
            return {...state, principalData: action.payload};
        case SET_VISITOR:
            return {...state, visitor: action.payload};
        case GET_VISITOR_DATA:
            return {...state, visitorData: action.payload};
        case GET_ADDED_CHAMPIONSHIPS:
            return {
                ...state,
                addedChampionships: action.payload.sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }),
            };
        case SET_SEASON:
            return {...state, season: action.payload};
        case GET_SEASONS:
            return {...state, seasons: action.payload};
        case GET_SEASON:
            return {...state, seasonStatistics: action.payload};
        case GET_SCORE_FREQUENCY:
            return {...state, seasonScoreFrequency: action.payload};
        case SET_ESTADO:
            return {...state, estado: action.payload};
        case GET_ESTADO:
            return {...state, estados: action.payload};
        case SET_CIDADE:
            return {...state, cidade: action.payload};
        case GET_CIDADE:
            return {...state, cidades: action.payload};
        case GET_MATCH_POISSON:
            return {
                ...state,
                poisson_ft: action.payload.ft_poisson,
                poisson_ht: action.payload.ht_poisson,
                chance_ft: action.payload.ft_chance,
                chance_ht: action.payload.ht_chance,
            };
        case POST_SEASON:
            return {
                ...state,
                addedChampionships: action.payload.sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }),
            };
        case DELETE_SEASON:
            return {
                ...state,
                addedChampionships: action.payload.sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }),
            };
        case POST_LOGIN:
            return {
                ...state,
                authorization: action.payload,
            }
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload,
            }
        case SET_AUTHORIZATION:
            return {
                ...state,
                authorization: action.payload,
            }
        case GET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case POST_USER:
            return {
                ...state,
                user: action.payload
            }
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case GET_USER_ROLES:
            return {
                ...state,
                edited_user_roles: action.payload
            }
        case GET_ALL_ROLES:
            return {
                ...state,
                all_roles: action.payload
            }
        case SET_EDITED_USER_ROLE_ID:
            return {
                ...state,
                edited_user_roles_id: action.payload
            }
        case SET_EDITED_USER_TELEFONE:
            return {
                ...state,
                user: {
                    ...state.user,
                    telefone: action.payload
                }
            }
        case SET_EDITED_USER_CEP:
            return {
                ...state,
                user: {
                    ...state.user,
                    cep: action.payload
                }
            }
        case SET_EDITED_USER_NUMERO:
            return {
                ...state,
                user: {
                    ...state.user,
                    numero: action.payload
                }
            }
        case SET_EDITED_USER_RUA:
            return {
                ...state,
                user: {
                    ...state.user,
                    rua: action.payload
                }
            }
        case SET_EDITED_USER_BAIRRO:
            return {
                ...state,
                user: {
                    ...state.user,
                    bairro: action.payload
                }
            }
        case SET_EDITED_USER_COMPLEMENTO:
            return {
                ...state,
                user: {
                    ...state.user,
                    complemento: action.payload
                }
            }
        case SET_RESIDENTE_EXTERIOR:
            return {
                ...state,
                user: {
                    ...state.user,
                    residenteExterior: action.payload
                }
            }
        case SET_ODD_MANDANTE:
            return {
                ...state,
                oddMandante: action.payload
            }
        case SET_ODD_VISITANTE:
            return {
                ...state,
                oddVisitante: action.payload
            }
        case SET_TIPITICACAO_JOGO:
            return {
                ...state,
                tipificacaoJogo: action.payload
            }
        case GET_RECOVER_PASSWORD:
            return {
                ...state,
                recover_password: action.payload
            }
        case POST_CHANGE_PASSWORD:
            return {
                ...state,
                change_password: action.payload
            }
        case GET_MARKET:
            return {
                ...state,
                market: {
                    ...action.payload,
                },
            }
        case GET_EDITED_MARKET:
            return {
                ...state,
                market: {
                    ...action.payload,
                    over_under_ft: action.payload.over_under_ft.map((item, index) => {
                        return {
                            ...item,
                            odd_over: state.market.over_under_ft[index].odd_over,
                            valor_over: +((state.market.over_under_ft[index].odd_over / item.fair_over - 1) * 100).toFixed(2),
                            odd_under: state.market.over_under_ft[index].odd_under,
                            valor_under: +((state.market.over_under_ft[index].odd_under / item.fair_under - 1) * 100).toFixed(2),
                        }
                    }),
                    handcap_asiatico: state.market.handcap_asiatico
                },
            }
        case SET_OVER_MARKET:
            return {
                ...state,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === action.payload.key ? {
                            ...item,
                            odd_over: action.payload.field === 'odd_over' ? action.payload.odd : item.odd_over,
                            valor_over: action.payload.field === 'odd_over' ? +((action.payload.odd / item.fair_over - 1) * 100).toFixed(2) : item.valor_over,
                            odd_under: action.payload.field === 'odd_under' ? action.payload.odd : item.odd_under,
                            valor_under: action.payload.field === 'odd_under' ? +((action.payload.odd / item.fair_under - 1) * 100).toFixed(2) : item.valor_under,
                        } : item)
                },
            }
        case SET_OVER_05:
            return {
                ...state,
                over_05: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_05' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_15:
            return {
                ...state,
                over_15: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_15' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_25:
            return {
                ...state,
                over_25: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_25' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_35:
            return {
                ...state,
                over_35: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_35' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_45:
            return {
                ...state,
                over_45: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_45' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_55:
            return {
                ...state,
                over_55: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_55' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_OVER_65:
            return {
                ...state,
                over_65: action.payload,
                market: {
                    ...state.market,
                    over_under_ft: state.market.over_under_ft.map(item =>
                        item.id === 'over_65' ? {
                            ...item,
                            odd: action.payload,
                            valor: +((action.payload / item.fair - 1) * 100).toFixed(2)
                        } : item)
                }
            }
        case SET_HANDCAP_MARKET:
            return {
                ...state,
                market: {
                    ...state.market,
                    handcap_asiatico: state.market.handcap_asiatico.map(item =>
                        item.id === action.payload.id ? {
                            ...item,
                            // ...item,
                            odd_mandante: 'mandante' === action.payload.lado ? action.payload.odd_mandante : item.odd_mandante,
                            odd_visitante: 'visitante' === action.payload.lado ? action.payload.odd_visitante : item.odd_visitante,
                            chance_mandante: 'mandante' === action.payload.lado ? action.payload.chance_mandante : item.chance_mandante,
                            chance_visitante: 'visitante' === action.payload.lado ? action.payload.chance_visitante : item.chance_visitante,
                            // // odd: action.payload.odd,
                            // // valor: +((action.payload.odd / item.fair - 1) * 100).toFixed(2)
                        } : item)
                },
            }
        case RESET_HANDCAP_MARKET:
            return {
                ...state,
                market: {
                    ...state.market,
                    handcap_asiatico: state.market.handcap_asiatico.map((item, index) => {
                        let visitante_favorito = state.oddMandante > state.oddVisitante ? -1 : 1
                        let mandante_favorito = -visitante_favorito
                        return {
                            ...item,
                            odd_mandante: 0.0,
                            odd_visitante: 0.0,
                            chance_mandante: -100.0,
                            chance_visitante: -100.0,
                            linha_mandante: Math.abs(item.linha_mandante) * mandante_favorito,
                            linha_visitante: Math.abs(item.linha_visitante) * visitante_favorito,
                        }
                    }),
                },
            }
        case SET_PRINCIPAL_GAMES:
            return {
                ...state,
                principalData: {
                    ...state.principalData,
                    jogos: action.payload,
                }
            }
        case SET_PRINCIPAL_GOALS_SCORED:
            return {
                ...state,
                principalData: {
                    ...state.principalData,
                    gols_pro_ft: {
                        ...state.principalData.gols_pro_ft,
                        qtd: action.payload,
                    }
                }
            }
        case SET_PRINCIPAL_GOALS_CONCEDED:
            return {
                ...state,
                principalData: {
                    ...state.principalData,
                    gols_contra_ft: {
                        ...state.principalData.gols_contra_ft,
                        qtd: action.payload,
                    }
                }
            }
        case SET_PRINCIPAL_SEASON_GOALS_MEAN:
            return {
                ...state,
                principalData: {
                    ...state.principalData,
                    gols_pro_ft: {
                        ...state.principalData.gols_pro_ft,
                        media_geral: action.payload,
                    }
                }
            }
        case SET_VISITOR_GAMES:
            return {
                ...state,
                visitorData: {
                    ...state.visitorData,
                    jogos: action.payload,
                }
            }
        case SET_VISITOR_GOALS_SCORED:
            return {
                ...state,
                visitorData: {
                    ...state.visitorData,
                    gols_pro_ft: {
                        ...state.visitorData.gols_pro_ft,
                        qtd: action.payload,
                    }
                }
            }
        case SET_VISITOR_GOALS_CONCEDED:
            return {
                ...state,
                visitorData: {
                    ...state.visitorData,
                    gols_contra_ft: {
                        ...state.visitorData.gols_contra_ft,
                        qtd: action.payload,
                    }
                }
            }
        case SET_VISITOR_SEASON_GOALS_MEAN:
            return {
                ...state,
                visitorData: {
                    ...state.visitorData,
                    gols_pro_ft: {
                        ...state.visitorData.gols_pro_ft,
                        media_geral: action.payload,
                    }
                }
            }
        case GET_TABLES:
            return {
                ...state,
                tables: action.payload,
            }
        default:
            return state;
    }
}

export default userReducers;
