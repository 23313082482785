import {
    Avatar,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";

import * as React from "react";
import * as PropTypes from "prop-types";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";

const TableHeadItem = (props) => {
    const {order, orderBy, column, onRequestSort} = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead key="table-head">
            <TableRow className="table-title">
                {column.map((item, index) => (
                    <TableCell
                        sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "1px solid black"}}
                        align={"center"}
                        key={item.id}
                        sortDirection={orderBy === item.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === item.id}
                            direction={orderBy === item.id ? order : 'asc'}
                            onClick={createSortHandler(item.id)}
                            className="enhaced-table-title"
                            hideSortIcon
                        >
                            {orderBy === item.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                            <Tooltip followCursor={true} title={item.alt} placement="top">
                                <Typography className="table-header">
                                    {item.heading}
                                </Typography>
                            </Tooltip>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeadItem.propTypes = {
    onRequestSort: PropTypes.any,
    orderBy: PropTypes.any
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const TableTypification = (props) => {
    const {data, column, title, image, defaultSort, highlight, className} = props;
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(defaultSort);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return (
        <TableContainer>
            <Table className={className} size="small">
                <TableHead key="Titulo">
                    <TableRow>
                        <TableCell
                            className="table-title"
                            sx={{fontSize: "0.8rem", borderBottom: "black"}}
                            colSpan={column.length + 1}
                            align={"center"}
                        >
                            {image &&
                                <Avatar className="team-flag" src={`${image}`} variant="square"/>
                            }
                            {title}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableHeadItem column={column}
                               order={order}
                               orderBy={orderBy}
                               onRequestSort={handleRequestSort}
                               rowCount={data.length}/>
                <TableBody>
                    {visibleRows.map((item, index) => (
                        <TableRowTypification key={index} item={item} column={column} rowIndex={index}
                                              highlight={highlight}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const AVG_GOALS = ["MGP", "MGC", "MGPHT", "MGCHT"];

function handleAlt(value) {
    if (value === "M")
        return "Os dados são referentes ao Mandante"

    if (value === "V")
        return "Os dados são referentes ao Visitante"

    if (value === "A")
        return "Os dados referentes ao Acumulado"

    if (value === "SFM")
        return "Super Favorito Mandante (Odd mínima 1,01 - Odd máxima 1,40)"

    if (value === "FM")
        return "Favorito Mandante (Odd mínima 1,41 - Odd máxima 1,80)"

    if (value === "MFM")
        return "Médio Favorito Mandante (Odd mínima 1,81 - Odd máxima 2,20)"

    if (value === "EQM")
        return "Equilibrado Mandante (Odd mínima 2,21)"

    if (value === "EQ")
        return "Equilibrado (Odd mínima 2,21)"

    if (value === "EQV")
        return "Equilibrado Visitante (Odd mínima 2,21)"

    if (value === "MFV")
        return "Médio Favorito Visitante (Odd mínima 1,81 - Odd máxima 2,2)"

    if (value === "FV")
        return "Favorito Visitante (Odd mínima 1,41 - Odd máxima 1,80)"

    if (value === "SFV")
        return "Super Favorito Visitante (Odd mínima 1,01 - Odd máxima 1,40)"

    return value
}

function handleTypificationStyle(value, columnItem) {
    if (columnItem.heading === "Tipo") {
        return "yellow";
    }

    if (columnItem.heading === "Visitante") {
        return "blue";
    }

    if (AVG_GOALS.includes(columnItem.heading)) {
        if (value > 20) {
            return "good";
        } else if (value > 2) {
            return "neutral";
        } else {
            return "bad";
        }
    }
}

function addMask(value, columnItem) {
    if (undefined === value) {
        return "erro"
    }
    if (columnItem.tipo === "int" || columnItem.tipo === "text") {
        return value
    }
    if (columnItem.tipo === "float") {
        return value.toFixed(2)
    }
    if (columnItem.tipo === "%") {
        return value.toFixed(2) + '%'
    }

    return value
}

const TableRowTypification = ({item, column, rowIndex, highlight}) => {
    var tipificacao = item.tipificacao
    if (tipificacao === 'EQM' || tipificacao === 'EQV') {
        tipificacao = 'EQ'
    }
    return (
        <TableRow className="line-color" highlight={(tipificacao === highlight) ? 'true' : 'false'} hover>
            {column.map((columnItem, index) => {
                return (
                    <Tooltip followCursor={true} key={index}
                             title={handleAlt(addMask(item[`${columnItem.value}`], columnItem))}
                             placement="top">
                        <TableCell
                            className={"poisson-status championship-table-cell col"}
                            data-status={handleTypificationStyle(item[`${columnItem.value}`], columnItem)}
                            sx={{fontSize: "0.8rem", padding: "2px", borderBottom: "black"}}
                            key={index}
                            align={"center"}
                        >
                            {addMask(item[`${columnItem.value}`], columnItem)}
                        </TableCell>
                    </Tooltip>
                );
            })}
        </TableRow>
    )
};