import {DataGrid} from "@mui/x-data-grid";
import * as React from "react";
import PropTypes from "prop-types";

export const MarketHandcapAsiaticoTable = (props) => {
    const {rows} = props
    const handcapAsiaticoColumns = [
        {
            field: "linha_mandante",
            headerName: "HAM",
            width: 70,
            description: "Handcap Asíatico Mandante",
            sortable: false,
        },
        {
            field: "odd_mandante",
            headerName: "OM",
            description: "Odd Mandante",
            width: 80,
            editable: true,
            sortable: false,
        },
        {
            field: "chance_mandante",
            headerName: "VM",
            width: 80,
            description: "Valor Mandante", sortable: false,
            cellClassName: (params) =>
                params.value > 10 ? "cell-green" : params.value > 0 ? 'cell-yellow' : 'cell-red',
            renderCell: (params) => (params.value + "%")
        },
        {
            field: "chance_visitante",
            headerName: "VV",
            width: 80,
            description: "Valor Visitante", sortable: false,
            cellClassName: (params) =>
                params.value > 10 ? "cell-green" : params.value > 0 ? 'cell-yellow' : 'cell-red',
            renderCell: (params) => (params.value + "%")
        },
        {
            field: "odd_visitante",
            headerName: "OV",
            width: 80,
            description: "Odd Visitante",
            editable: true,
            sortable: false,
        },
        {
            field: "linha_visitante",
            headerName: "HAV",
            width: 70,
            description: "Handcap Asiático Visitante",
            sortable: false,
        },
    ]

    return (
        <div className="datagrid-container">
            <DataGrid
                disableColumnMenu
                style={{width: '95%'}}
                className="overUnder"
                getRowId={(row) => row.id}
                rows={rows}
                columns={handcapAsiaticoColumns}
                pageSize={25}
                autoHeight={true}
                rowHeight={25}
                headerHeight={25}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                onCellEditCommit={props.onCellEditCommit}
            />
        </div>
    );
}

MarketHandcapAsiaticoTable.propTypes = {
    rows: PropTypes.array.isRequired,
    onCellEditCommit: PropTypes.func.isRequired,
}