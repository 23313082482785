export const GET_CHAMPIONSHIPS_CARROUCEL = "GET_CHAMPIONSHIPS_CARROUCEL"
export const GET_CHAMPIONSHIPS = "GET_CHAMPIONSHIPS";
export const SET_CHAMPIONSHIP = "SET_CHAMPIONSHIP";
export const GET_SEASON_TEAMS = "GET_CHAMPIONSHIP_TEAMS";
export const SET_PRINCIPAL = "SET_PRINCIPAL";
export const GET_PRINCIPAL_DATA = "GET_PRINCIPAL_DATA";
export const SET_VISITOR = "SET_VISITOR";
export const GET_VISITOR_DATA = "GET_VISITOR_DATA";
export const GET_ADDED_CHAMPIONSHIPS = "GET_ADDED_CHAMPIONSHIPS";
export const SET_SEASON = "SET_SEASON";
export const GET_SEASONS = "GET_SEASONS";
export const GET_SEASON = "GET_SEASON";
export const GET_MATCH_POISSON = "GET_MATCH_POISSON";
export const POST_SEASON = "POST_SEASON";
export const DELETE_SEASON = "DELETE_SEASON";
export const POST_LOGIN = "POST_LOGIN";
export const SET_USERNAME = "SET_USERNAME"
export const SET_AUTHORIZATION = "SET_AUTHORIZATION"
export const GET_USER = "GET_USER"
export const GET_USERS = "GET_USERS"
export const POST_USER = "POST_USER"
export const PUT_USER = "PUT_USER"
export const GET_ESTADO = "GET_ESTADO"
export const SET_ESTADO = "SET_ESTADO"
export const GET_CIDADE = "GET_CIDADE"
export const SET_CIDADE = "SET_CIDADE"
export const SET_RESIDENTE_EXTERIOR = "SET_RESIDENTE_EXTERIOR"
export const GET_USER_ROLES = "GET_USER_ROLES"
export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const SET_EDITED_USER_ROLE_ID = "SET_EDITED_USER_ROLE_ID"
export const SET_EDITED_USER_TELEFONE = "SET_EDITED_USER_TELEFONE"
export const SET_EDITED_USER_RUA = "SET_EDITED_USER_RUA"
export const SET_EDITED_USER_BAIRRO = "SET_EDITED_USER_BAIRRO"
export const SET_EDITED_USER_CEP = "SET_EDITED_USER_CEP"
export const SET_EDITED_USER_COMPLEMENTO = "SET_EDITED_USER_COMPLEMENTO"
export const SET_EDITED_USER_NUMERO = "SET_EDITED_USER_NUMERO"
export const GET_RECOVER_PASSWORD = "GET_RECOVER_PASSWORD"
export const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD"
export const GET_SCORE_FREQUENCY = "GET_SCORE_FREQUENCY"
export const SET_ODD_MANDANTE = "SET_ODD_MANDANTE"
export const SET_ODD_VISITANTE = "SET_ODD_VISITANTE"
export const SET_TIPITICACAO_JOGO = "SET_TIPITICACAO_JOGO"
export const GET_MARKET = "GET_MARKET"
export const SET_OVER_05 = "SET_OVER_05"
export const SET_OVER_15 = "SET_OVER_15"
export const SET_OVER_25 = "SET_OVER_25"
export const SET_OVER_35 = "SET_OVER_35"
export const SET_OVER_45 = "SET_OVER_45"
export const SET_OVER_55 = "SET_OVER_55"
export const SET_OVER_65 = "SET_OVER_65"
export const SET_HANDCAP_MARKET = "SET_HANDCAP_MARKET"
export const RESET_HANDCAP_MARKET = "RESET_HANDCAP_MARKET"
export const SET_PRINCIPAL_GAMES = "SET_PRINCIPAL_GAMES"
export const SET_PRINCIPAL_GOALS_SCORED = "SET_PRINCIPAL_GOALS_SCORED"
export const SET_PRINCIPAL_GOALS_CONCEDED = "SET_PRINCIPAL_GOALS_CONCEDED"
export const SET_PRINCIPAL_SEASON_GOALS_MEAN = "SET_PRINCIPAL_SEASON_GOALS_MEAN"
export const SET_VISITOR_GAMES = "SET_VISITOR_GAMES"
export const SET_VISITOR_GOALS_SCORED = "SET_VISITOR_GOALS_SCORED"
export const SET_VISITOR_GOALS_CONCEDED = "SET_VISITOR_GOALS_CONCEDED"
export const SET_VISITOR_SEASON_GOALS_MEAN = "SET_VISITOR_SEASON_GOALS_MEAN"
export const GET_EDITED_MARKET = "GET_EDITED_MARKET"
export const SET_OVER_MARKET = "SET_OVER_MARKET"
export const GET_TABLES = "GET_TABLES"

const API_URL = process.env.REACT_APP_API_URL;

const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
};

const login_headers = {
    "Access-Control-Allow-Origin": "*",
}

export const getChampionshipsCarroucel = () => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league/championships`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_CHAMPIONSHIPS_CARROUCEL,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getChampionships = () => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_CHAMPIONSHIPS,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getAddedChampionships = () => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league/seasons`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_ADDED_CHAMPIONSHIPS,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const setOverMarket = (odd) => (dispatch) => {
    dispatch({
        type: SET_OVER_MARKET,
        payload: odd,
    });
}

export const resetHandcapMarket = (handcap) => (dispatch) => {
    dispatch({
        type: RESET_HANDCAP_MARKET,
        payload: handcap,
    });
}

export const getHandcapMarket = (season_id, home_id, away_id, side, odd_mandante, odd_visitante, line, odd) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/principal/${home_id}/visitor/${away_id}/market/${side}/${odd_mandante}/${odd_visitante}/${line}/${odd}`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: SET_HANDCAP_MARKET,
                    payload: json,
                });
            } else {
                throw new Error("Unable to fetch market");
            }
        };
    } catch (e) {
        throw e;
    }
}

export const setPrincipalGoalsScored = (goals) => (dispatch) => {
    dispatch({
        type: SET_PRINCIPAL_GOALS_SCORED,
        payload: goals,
    })
}

export const setPrincipalGoalsConceded = (goals) => (dispatch) => {
    dispatch({
        type: SET_PRINCIPAL_GOALS_CONCEDED,
        payload: goals,
    })
}

export const setVisitorGoalsScored = (goals) => (dispatch) => {
    dispatch({
        type: SET_VISITOR_GOALS_SCORED,
        payload: goals,
    })
}

export const setVisitorGoalsConceded = (goals) => (dispatch) => {
    dispatch({
        type: SET_VISITOR_GOALS_CONCEDED,
        payload: goals,
    })
}

export const setChampionship = (championship) => (dispatch) => {
    dispatch({
        type: SET_CHAMPIONSHIP,
        payload: championship,
    });
};

export const setUsername = (username) => (dispatch) => {
    dispatch({
        type: SET_USERNAME,
        payload: username,
    })
}

export const getSeasons = () => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_SEASONS,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const setSeason = (season) => (dispatch) => {
    dispatch({
        type: SET_SEASON,
        payload: season,
    });
};

export const getSeasonTeams = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/teams`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_SEASON_TEAMS,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const setPrincipal = (team) => (dispatch) => {
    dispatch({
        type: SET_PRINCIPAL,
        payload: team,
    });
};

export const getPrincipalData = (season_id, team_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/team/${team_id}/home`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_PRINCIPAL_DATA,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const setVisitor = (team) => (dispatch) => {
    dispatch({
        type: SET_VISITOR,
        payload: team,
    });
};

export const getVisitorData = (season_id, team_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/team/${team_id}/away`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_VISITOR_DATA,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getPoissonData = (season_id, home_id, away_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/principal/${home_id}/visitor/${away_id}`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_MATCH_POISSON,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch poisson!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getMarketData = (season_id, home_id, away_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/principal/${home_id}/visitor/${away_id}/market`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_MARKET,
                    payload: json,
                });
            } else {
                throw new Error("Unable to fetch market");
            }
        };
    } catch (e) {
        throw e;
    }
};

export const getEditedMarketData = (season_id, home_id, away_id, home_games, home_scored, home_conceded, home_general_mean, away_games, away_scored, away_conceded, away_general_mean, odd_btts) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/principal/${home_id}/${home_games}/${home_scored}/${home_conceded}/${home_general_mean}/visitor/${away_id}/${away_games}/${away_scored}/${away_conceded}/${away_general_mean}/btts/${odd_btts}/market`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_EDITED_MARKET,
                    payload: json,
                });
            } else {
                throw new Error("Unable to fetch market");
            }
        };
    } catch (e) {
        throw e;
    }
};

export const updateSeason = (season_id) => {
    try {
        return async (dispatch) => {
            await fetch(`${API_URL}/league/season/${season_id}`, {
                method: "PUT",
                headers: headers,
            });
        };
    } catch (error) {
        console.log(error);
    }
};

export const removeSeason = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league/season/${season_id}`, {
                method: "DELETE",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: DELETE_SEASON,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch poisson!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const addSeason = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/league/season/${season_id}`, {
                method: "POST",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: POST_SEASON,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch poisson!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getSeason = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/league/statistics`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_SEASON,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getTables = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/tables`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_TABLES,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
};

export const getSeasonScoreFrequency = (season_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(
                `${API_URL}/league/season/${season_id}/league/frequency`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_SCORE_FREQUENCY,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch score frequency!");
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const login = (data) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/token`, {
                method: "POST",
                headers: login_headers,
                body: data
            });
            const json = await result.json();
            if (json.detail) {
                throw json
            }
            if (json) {
                let jwtData = json.access_token.split('.')[1]
                const decodedJwtJsonData = window.atob(jwtData)
                const decodedJwtData = JSON.parse(decodedJwtJsonData)
                json.roles = decodedJwtData.scopes
                dispatch({
                    type: POST_LOGIN,
                    payload: json,
                });
                return dispatch(getUser(json.access_token))
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const setAuthorization = (authorization) => (dispatch) => {
    dispatch({
        type: SET_AUTHORIZATION,
        payload: authorization,
    });
}

export const getUser = (token) => {
    try {
        return async (dispatch) => {
            const h = headers
            h.authorization = `bearer ${token}`
            const result = await fetch(`${API_URL}/users/me`, {
                method: "GET",
                headers: h
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_USER,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const getUsers = (token) => {
    try {
        return async (dispatch) => {
            const h = headers
            h.authorization = `bearer ${token}`
            const result = await fetch(`${API_URL}/users`, {
                method: "GET",
                headers: h
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_USERS,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const getEstados = () => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/estados`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_ESTADO,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const setEstado = (estado) => (dispatch) => {
    dispatch({
        type: SET_ESTADO,
        payload: estado,
    });
};

export const getCidades = (estado_id) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/cidades/${estado_id}`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_CIDADE,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch!");
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const setCidade = (cidade) => (dispatch) => {
    dispatch({
        type: SET_CIDADE,
        payload: cidade,
    });
};

export const setResidenteExterior = (residenteExterior) => (dispatch) => {
    dispatch({
        type: SET_RESIDENTE_EXTERIOR,
        payload: residenteExterior
    })
}

export const setOddMandante = (oddMandante) => (dispatch) => {
    dispatch({
        type: SET_ODD_MANDANTE,
        payload: oddMandante
    })
}

export const setOddVisitante = (oddVisitante) => (dispatch) => {
    dispatch({
        type: SET_ODD_VISITANTE,
        payload: oddVisitante
    })
}

export const setTipificacaoJogo = (tipiticacao) => (dispatch) => {
    dispatch({
        type: SET_TIPITICACAO_JOGO,
        payload: tipiticacao
    })
}

export const createUser = (data) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/create_user`, {
                method: "POST",
                headers: headers,
                body: data
            });
            const json = await result.json();
            if (result.status >= 400) {
                throw json
            }
            if (json) {
                dispatch({
                    type: POST_USER,
                    payload: json,
                });
                return Promise.resolve(true)
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const updateUser = (user) => {
    try {
        const json = {...user, password: ""}
        return async (dispatch) => {
            await fetch(`${API_URL}/user/update`, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(json)
            });
            return Promise.resolve(true)
        };
    } catch (error) {
        console.log(error);
    }
};

export const updateUserExpirationDate = (id, validade) => {
    try {
        console.log(validade)
        return async (dispatch) => {
            await fetch(`${API_URL}/users/${id}/expiration/${validade}`, {
                method: "PUT",
                headers: headers
            });
            return Promise.resolve(true)
        };
    } catch (error) {
        console.log(error);
    }
};

export const getUserRoles = (id, token) => {
    try {
        return async (dispatch) => {
            const h = headers
            h.authorization = `bearer ${token}`
            const result = await fetch(`${API_URL}/users/${id}/roles`, {
                method: "GET",
                headers: h
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_USER_ROLES,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const getAllRoles = (token) => {
    try {
        return async (dispatch) => {
            const h = headers
            h.authorization = `bearer ${token}`
            const result = await fetch(`${API_URL}/users/roles`, {
                method: "GET",
                headers: h
            });
            const json = await result.json();
            if (json) {
                dispatch({
                    type: GET_ALL_ROLES,
                    payload: json,
                });
            } else {
                console.log("Unable to fetch login!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export const updateUserRole = (token, user_id, role_id, value) => {
    try {
        const h = headers
        h.authorization = `bearer ${token}`
        return async (dispatch) => {
            await fetch(`${API_URL}/users/${user_id}/role/${role_id}/${value}`, {
                method: "PUT",
                headers: h,
            });
        };
    } catch (error) {
        console.log(error);
    }
};

export const updateUserUsageTerm = (token, user_id, value) => {
    try {
        const h = headers
        h.authorization = `bearer ${token}`
        return async (dispatch) => {
            await fetch(`${API_URL}/users/${user_id}/term/${value}`, {
                method: "PUT",
                headers: h,
            });
        };
    } catch (error) {
        console.log(error);
    }
};


export const updateUserRoles = (token, user_id, roles) => {
    try {
        const h = headers
        h.authorization = `bearer ${token}`
        return async () => {
            await fetch(`${API_URL}/users/${user_id}/role/update_roles`, {
                method: "PUT",
                headers: h,
                body: JSON.stringify(roles)
            });
        };
    } catch (error) {
        console.log(error)
    }
}


export const setEditedUserRoleId = (user_id) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_ROLE_ID,
        payload: user_id,
    });
};

export const setEditedUserTelefone = (telefone) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_TELEFONE,
        payload: telefone
    })
}

export const setEditedUserCep = (cep) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_CEP,
        payload: cep
    })
}

export const setEditedUserRua = (rua) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_RUA,
        payload: rua
    })
}

export const setEditedUserNumero = (numero) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_NUMERO,
        payload: numero
    })
}

export const setEditedUserComplemento = (complemento) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_COMPLEMENTO,
        payload: complemento
    })
}

export const setEditedUserBairro = (bairro) => (dispatch) => {
    dispatch({
        type: SET_EDITED_USER_BAIRRO,
        payload: bairro
    })
}


export const recoverPassword = (email) => {
    try {
        return async (dispatch) => {
            const result = await fetch(`${API_URL}/users/${email}/recover`, {
                method: "GET",
                headers: headers
            });
            const json = await result.json()
            if (result.status >= 400) {
                throw json
            }
            if (json) {
                dispatch({
                    type: GET_RECOVER_PASSWORD,
                    payload: json,
                });
                return Promise.resolve(true)
            } else {
                console.log("Unable to fetch recover password!")
            }
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const changePassword = (user_id, data, token) => {
    try {
        return async (dispatch) => {
            const h = login_headers
            h.authorization = `bearer ${token}`
            const result = await fetch(`${API_URL}/users/${user_id}/password`, {
                method: "POST",
                headers: h,
                body: data
            });
            const json = await result.json();
            if (result.status >= 400) {
                throw json
            }
            if (json) {
                dispatch({
                    type: POST_CHANGE_PASSWORD,
                    payload: json,
                });
                return Promise.resolve(true)
            } else {
                console.log("Unable to change password!")
            }
        };
    } catch (error) {
        console.log(error);
    }
}