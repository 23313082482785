import {useDispatch, useSelector} from "react-redux";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {getUserRoles, updateUserRole, updateUserRoles} from "../../actions";

function Role() {
  const dispatch = useDispatch();
  const {edited_user_roles, all_roles, authorization, edited_user_roles_id} = useSelector(
    state => state.userReducers
  );

  const handleDefaultState = (item) => {
    return edited_user_roles.some(r => r.id === item.id)
  }

  const handleCheckboxChange = async (event, item) => {
    await dispatch(updateUserRole(authorization.access_token, edited_user_roles_id, item.id, event.target.checked))
    await dispatch(getUserRoles(edited_user_roles_id, authorization.access_token))
  }

  const handleSelectAll = async (event) => {
    const roles = {
      rules: []
    };
    all_roles.map((item) => {
      if (item.role !== 'admin') {
        if (!edited_user_roles.some(r => r.id === item.id)) {
          event.target.checked = true
          roles.rules.push({id: item.id, active: true})
        }
      }
    });
    await dispatch(updateUserRoles(authorization.access_token, edited_user_roles_id, roles)).then()
    dispatch(getUserRoles(edited_user_roles_id, authorization.access_token)).then()
  };

  const handleDeselectAll = async (event) => {
    const roles = {
      rules: []
    };
    all_roles.map(async (item) => {
      if (item.role !== 'admin') {
        if (edited_user_roles.some(r => r.id === item.id)) {
          event.target.checked = false
          roles.rules.push({id: item.id, active: false})
        }
      }
    });
    await dispatch(updateUserRoles(authorization.access_token, edited_user_roles_id, roles)).then()
    dispatch(getUserRoles(edited_user_roles_id, authorization.access_token)).then()
  };

  return (
    <div style={{height: 400, width: "100%"}}>
      <FormGroup row>
        {all_roles.map((item, index) => (
          <FormControlLabel key={index} control={
            <Checkbox checked={handleDefaultState(item)}
                      onChange={e => handleCheckboxChange(e, item)}/>}
                            label={item.role}/>
        ))}
      </FormGroup>
      <Button onClick={e => handleSelectAll(e)}>
        Selecionar Todos
      </Button>
      <Button onClick={e => handleDeselectAll(e)}>
        Desmarcar Todos
      </Button>
    </div>
  )
}

export default Role;