import React, {useEffect} from 'react';
import {Avatar, Backdrop, Box, Button, CircularProgress, Grid, Link, TextField, Typography} from "@mui/material";
import {login} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {setToken} from "../../Auth";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import {usePostHog} from 'posthog-js/react'
import Subscription from "../subscription/Subscription";

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const posthog = usePostHog();
    const {authorization, user} = useSelector((state) => state.userReducers);
    const [loginLoading, setLoginLoading] = React.useState(false);

    const handleSubmit = async (event) => {
        setLoginLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        posthog?.capture('clicked_log_in')
        await dispatch(login(data)).catch(error => {
            if (error.detail === "User don't have permission to login") {
                toast.error(
                    "Você não tem nenhum plano vinculado ao seu login. Para ter acesso ao Prematch você precisa ter um plano ativo. Estamos cobrando um valor simbólico até 31 de dezembro de 2023. Caso tenha interesse, entrar em contato no WhatsApp 83 981085051 e falar com o Murilo Mussumago."
                )
            } else {
                toast.error(error.detail)
            }
        })
        setLoginLoading(false)
    }

    useEffect(() => {
        if (authorization.access_token && user.nome !== "") {
            setToken(authorization.access_token)
            let expiration = new Date(user.validade)
            let now = new Date()
            let miliseconds = expiration - now
            let total_seconds = parseInt(Math.floor(miliseconds / 1000));
            let total_minutes = parseInt(Math.floor(total_seconds / 60));
            let total_hours = parseInt(Math.floor(total_minutes / 60));
            let days = parseInt(Math.floor(total_hours / 24));
            if (days < 3 && days > -1) {
                if (days > 0)
                    toast.warn(`Sua assinatura expira em ${days} dia(s)`)
                else
                    toast.warn('Sua assinatura expira hoje')
            }
            posthog?.identify(user.id, {
                email: user.email,
            })
            posthog?.group('company', 'prematch.com.br')
            navigate("/match")
        }
    }, [posthog, dispatch, authorization, navigate, user]);

    return (
        <Grid container>
            <Backdrop open={loginLoading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid item xs={12} sm={12} md={0} lg={2}>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
                <Grid className="subscription-box" paddingBottom='16px'>
                    <Subscription/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={0} lg={2}></Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} display='flex'>
                    <Grid className='description-box' padding={1}>
                        <Grid>
                            <Avatar alt="Simple Sports Football & Soccer Badge Logo"
                                    src={require('../../static/soccer_badge.png')}
                                    sx={{width: 256, height: 256}} component={Link} to="/"/>
                        </Grid>
                        <Grid padding={2}>
                            <Typography variant='body1' align='justify'>
                                O Prematch é um software que oferece informações e estatísticas sobre os principais
                                campeonatos do mundo relacionado ao esporte futebol que pode ser utilizado como ferramenta
                                de auxilio para operações no mercado de investimento esportivo.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Box className="login-box">
                        <Typography component="h1" variant="h5"
                                    style={{fontWeight: '500', marginBottom: '1rem'}}>
                            Login
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField required={true} id="username" label="E-mail" name="username"
                                       autoComplete="email"
                                       autoFocus margin="normal" fullWidth={true}/>
                            <TextField required id="password" label="senha" name="password" type="password"
                                       autoComplete="current-password" margin="normal" fullWidth={true}/>
                            {/*<FormControlLabel control={<CheckBox value="remember" color="primary"/>} label="Salvar senha"/>*/}
                            <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>Entrar</Button>
                            <Grid container>
                                <Grid item xs className="text-align-left">
                                    <Link href="#" variant="body2"
                                          className="color-grey"
                                          onClick={() => {
                                              navigate("/forget")
                                          }}>
                                        Esqueceu a senha?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2"
                                          className="color-grey"
                                          onClick={() => {
                                              navigate("/register")
                                          }}>
                                        {"Crie sua conta!"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={0} lg={2}></Grid>
            </Grid>
        </Grid>
    )
}

export default Login;
