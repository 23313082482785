import React, {useEffect} from "react";
import {Alert, Backdrop, CircularProgress, Grid} from "@mui/material";
import {DropDown} from "../../components/common/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {
    getAddedChampionships,
    getChampionships,
    getMarketData,
    getPoissonData,
    getPrincipalData,
    getSeason,
    getSeasonScoreFrequency,
    getSeasonTeams,
    getTables,
    getVisitorData,
    setChampionship,
    setOddMandante,
    setOddVisitante,
    setPrincipal,
    setSeason,
    setTipificacaoJogo,
    setVisitor,
} from "../../actions";
import BasicTabs from "../../components/common/MatchTabs";
import {Watermark} from "@hirohe/react-watermark";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";

function Match() {

    const {
        addedChampionships,
        championship,
        seasonTeams,
        principal,
        visitor,
        principalData,
        visitorData,
        user,
    } = useSelector((state) => state.userReducers);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formValues, setFormValues] = React.useState({});
    const [disableDropbox, setDisableDropbox] = React.useState(false);
    const [matchLoading, setMatchLoading] = React.useState(false);

    const handleChampionshipChange = async (event) => {
        setMatchLoading(true)
        setDisableDropbox(true)
        dispatch(setChampionship(event.target.value));
        await dispatch(getSeasonTeams(event.target.value));
        await dispatch(setSeason(event.target.value));
        await dispatch(getSeason(event.target.value));
        await dispatch(getSeasonScoreFrequency(event.target.value));
        await dispatch(getTables(event.target.value));
        setDisableDropbox(false)
        const {value, name} = event.target;
        setFormValues({...formValues, [name]: value});
        dispatch(setPrincipal(""));
        dispatch(setVisitor(""));
        setMatchLoading(false)
        dispatch(setTipificacaoJogo('indefinido'))
        dispatch(setOddVisitante(1))
        dispatch(setOddMandante(1))
    };

    const handlePrincipalChange = async (event) => {
        setMatchLoading(true)
        await dispatch(setPrincipal(event.target.value));
        await dispatch(getPrincipalData(championship, event.target.value)).then();
        if (visitor !== "") {
            await dispatch(getPoissonData(championship, event.target.value, visitor)).then().catch(
                error => {
                    toast.error("falha ao carregar dados de poisson do Mandante!")
                }
            )
            await dispatch(getMarketData(championship, event.target.value, visitor)).then().catch(
                error => {
                    toast.error("Falha ao darregar dados de mercado do Mandante!");
                }
            )
        }
        setMatchLoading(false)
    };

    const handleVisitorChange = async (event) => {
        setMatchLoading(true)
        await dispatch(setVisitor(event.target.value));
        await dispatch(getVisitorData(championship, event.target.value)).then();
        if (principal !== "") {
            await dispatch(getPoissonData(championship, principal, event.target.value)).then().catch(
                error => {
                    toast.error("falha ao carregar dados de poisson do Visitante!")
                }
            )
            await dispatch(getMarketData(championship, principal, event.target.value)).then().catch(
                error => {
                    toast.error("Falha ao darregar dados de mercado do Visitante!");
                }
            )
        }
        setMatchLoading(false)
    };

    useEffect(() => {
        dispatch(getAddedChampionships()).then();
        dispatch(getChampionships()).then();
        if (!user.termo) {
            navigate("/accept")
        }
    }, [dispatch, navigate, user.termo, championship]);

    return (
        <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
            {/*<Grid item xs={12} className='match-title'>*/}
            {/*  <h1>Match</h1>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={12} md={12} className='match-dropdown'>
                <DropDown
                    labelId="match-championship-selection-label"
                    id="match-championship-selection"
                    value={championship}
                    onChange={handleChampionshipChange}
                    label="Campeonato"
                    items={addedChampionships}
                    variant="standard"
                    sx={{m: 1, minWidth: 120}}
                />
                <DropDown
                    labelId="match-principal-selection-label"
                    id="match-principal-selection"
                    value={principal}
                    onChange={handlePrincipalChange}
                    label="Mandante"
                    items={seasonTeams}
                    variant="standard"
                    sx={{m: 1, minWidth: 120}}
                    disabled={disableDropbox}
                />
                <DropDown
                    labelId="match-visitor-selection-label"
                    id="match-visitor-selection"
                    value={visitor}
                    onChange={handleVisitorChange}
                    label="Visitante"
                    items={seasonTeams}
                    variant="standard"
                    sx={{m: 1, minWidth: 120}}
                    disabled={disableDropbox}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {principalData.minutagem_ft["0-45"].marcados === 0 || principalData.minutagem_ft["46-90"].marcados === 0 ||
                principalData.minutagem_ft["0-45"].sofridos === 0 || principalData.minutagem_ft["46-90"].sofridos === 0 ||
                visitorData.minutagem_ft["0-45"].marcados === 0 || visitorData.minutagem_ft["46-90"].marcados === 0 ||
                visitorData.minutagem_ft["0-45"].sofridos === 0 || visitorData.minutagem_ft["46-90"].sofridos === 0 ?
                    <Alert variant="filled" severity="warning">Equipes cujo não tenham marcado ou sofrido gols em algum
                        periodo
                        das partidas terão dados inconclusivos e não recomendamos a análise exibida!</Alert> : ""}
                <Watermark text={"..." + user.email.split('@')[0] + "..."} textSize={20} opacity={0.1} rotate={30}
                           lineHeight={'0.2rem'}
                           gutter={0}
                           textColor={"black"}>
                    <div style={{background: '#7986cb'}}>
                        <Backdrop open={matchLoading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                        <BasicTabs/>
                    </div>
                </Watermark>
            </Grid>
        </Grid>
    );
}

export default Match;
