import {Avatar, Grid} from "@mui/material";
import React from "react";
import {useMediaQuery} from "react-responsive";
import Carousel from "react-material-ui-carousel";

export const CarouselPrematch = (props) => {
    let {items, className} = props;

    const isExtraLargeScreen = useMediaQuery({query: '(min-width: 1920px)'})
    const isLargeScreen = useMediaQuery({query: '(max-width: 1920px)'})
    const isMediumScreen = useMediaQuery({query: '(max-width: 1280px)'})
    const isSmallScreen = useMediaQuery({query: '(max-width: 768px)'})

    let chunkSize = 1;
    if (isExtraLargeScreen) {
        chunkSize = 12;
    }

    if (isLargeScreen) {
        chunkSize = 12;
    }

    if (isMediumScreen) {
        chunkSize = 4;
    }

    if (isSmallScreen) {
        chunkSize = 2;
    }

    // const chunkSize = 6;
    const itemChunks = [];
    if (!items) {
        items = []
    }
    for (let i = 0; i < items.length; i += chunkSize) {
        itemChunks.push(items.slice(i, i + chunkSize));
    }
    //
    // const lastChunk = items.slice((itemChunks.length - 1) * chunkSize);
    // console.log(itemChunks.length, chunkSize, lastChunk)
    // while (lastChunk.length < chunkSize) {
    //     lastChunk.push(...items.slice(0, chunkSize - lastChunk.length));
    // }
    // console.log(lastChunk);
    // lastChunk.push(lastChunk)

    return (
        <Carousel
            duration={2000}
            interval={6000}
            indicators={true}
            animation="fade"
            className={className}
        >
            {itemChunks.map((chunk, index) => (
                <ItemChunk key={index} items={chunk}/>
            ))}
        </Carousel>
    );
}

function ItemChunk(props) {
    return (
        <Grid container spacing={4} style={{width: '100%', margin: 'auto'}}>

            {props.items.map((item, index) => (
                <Grid item xs={6} sm={3} md={1} lg={1} key={index}>
                    <Item item={item}/>
                </Grid>
            ))}

        </Grid>
    );
}

function Item(props) {
    const {item} = props
    return (
        <Grid
            style={{padding: '10px', width: '120px', height: '120px', background: 'none'}}>
            <Avatar src={item.image}
                    style={{
                        width: '100%',
                        height: '100%',
                        background: 'white',
                        objectFit: 'contain',
                        borderRadius: '10%'
                    }}
                    alt={item.name} variant='square'
            />
        </Grid>
    )
}