import {Box, Button, Checkbox, FormControlLabel, Grid, Modal, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {DropDown} from "../../components/common/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {createUser, getCidades, getEstados, setCidade, setEstado, setResidenteExterior} from "../../actions";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import Term from "../term/Term";

function Register() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {estados, estado, cidades, cidade, user} = useSelector(
        (state) => state.userReducers
    );
    const [formValues, setFormValues] = React.useState({});
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'lightgray',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: '80%',
        display: 'block',
        // margin: '40px',
        padding: '20px',
        borderRadius: '20px',
    };

    function validarCPF(cpf) {
        if (user.residenteExterior)
            return true
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf === '') return false;
        // Elimina CPFs invalidos conhecidos
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false;
        // Valida 1o digito
        var add = 0;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        var rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9)))
            return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10)))
            return false;
        return true;
    }

    function validarEmail(email) {
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let matches = email.match(validRegex)
        if (matches) {
            return true
        }
        return false
    }

    useEffect(() => {
        dispatch(getEstados()).then()
        // if (user.id !== "") {
        //   navigate("/login")
        // }
    }, [dispatch, navigate, user])

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        let object = {};
        data.forEach((value, key) => object[key] = value);
        if (!validarCPF(data.get('cpf'))) {
            toast.error('CPF Inválido')
            return
        }
        if (!validarEmail(data.get('email'))) {
            toast.error('Email Inválido. Digite o email completo. Exemplo: user@gmail.com')
            return
        }
        if (data.get('password') !== data.get('repetir password')) {
            toast.error('As senhas devem ser iguais')
            return
        }
        let userRegistered = await dispatch(createUser(JSON.stringify(object))).catch(error => {
            toast.error(error.detail)
        })
        if (userRegistered) {
            toast.success("Usuário registrado com Successo. Verifique seu e-mail para ativação da conta!")
            navigate("/login")
        }
    };

    const handleEstadoChange = (event) => {
        dispatch(setEstado(event.target.value));
        dispatch(getCidades(event.target.value)).then();
        const {value, name} = event.target;
        setFormValues({...formValues, [name]: value})
        dispatch(setCidade(""));
    };

    const handleCidadeChange = (event) => {
        dispatch(setCidade(event.target.value))
    };

    const handleCPF = (event) => {
        let valid = validarCPF(event.target.value)
        if (!valid) {

        }
    }

    const handleEmail = (event) => {
        let valid = validarEmail(event.target.value)
        if (!valid) {

        }
    }

    const handleAbroadChange = (event) => {
        dispatch(setResidenteExterior(event.target.checked))
        if (event.target.checked) {
            dispatch(setEstado(99))
            dispatch(getCidades(99)).then();
            dispatch(setCidade(9900001))
        } else {
            dispatch(setEstado(""))
            dispatch(setCidade(""))
        }

    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box className='register-box' align='left'>
                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5" align="left"
                                        style={{fontWeight: '500', marginBottom: '1rem'}}>
                                Crie sua conta
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="fullName"
                                    label="Nome Completo"
                                    autoComplete="full-name"
                                    name="nome"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox checked={user.residenteExterior}
                                                                     onChange={handleAbroadChange}/>}
                                                  label="Residente no Exterior"
                                                  name="exterior"/>
                                <TextField
                                    fullWidth
                                    id="cpf"
                                    label="CPF"
                                    autoComplete="cpf"
                                    name="cpf"
                                    onChange={handleCPF}
                                    disabled={user.residenteExterior}
                                    required={!user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Telefone"
                                    autoComplete="tel"
                                    name="telefone"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h2" variant="h6">
                                    Endereço
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={!user.residenteExterior}
                                    fullWidth
                                    id="cep"
                                    label="CEP"
                                    autoComplete="postal-code"
                                    name="cep"
                                    disabled={user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={!user.residenteExterior}
                                    fullWidth
                                    id="street"
                                    label="Rua"
                                    autoComplete="street-address1"
                                    name="rua"
                                    disabled={user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="number"
                                    label="Número"
                                    autoComplete="street-address2"
                                    name="numero"
                                    disabled={user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="complemento"
                                    label="Complemento"
                                    autoComplete="street-address3"
                                    name="complemento"
                                    disabled={user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={!user.residenteExterior}
                                    fullWidth
                                    id="neighborhood"
                                    label="Bairro"
                                    autoComplete="street-address3"
                                    name="bairro"
                                    disabled={user.residenteExterior}
                                />
                            </Grid>
                            <Grid item xs={12} className="register">
                                <DropDown
                                    labelId="estados-label"
                                    id="estados"
                                    value={estado}
                                    onChange={handleEstadoChange}
                                    label="Estados"
                                    items={estados}
                                    // variant="standard"
                                    name="estado"
                                    sx={{m: 1, minWidth: 250}}
                                />
                            </Grid>
                            <Grid item xs={12} className="register">
                                <DropDown
                                    labelId="cidades-label"
                                    id="cidades"
                                    value={cidade}
                                    onChange={handleCidadeChange}
                                    label="Cidades"
                                    items={cidades}
                                    // variant="standard"
                                    name="cidade"
                                    sx={{m: 1, minWidth: 250}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h6">
                                    Login
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    autoComplete="email"
                                    name="email"
                                    onChange={handleEmail}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    label="Senha"
                                    autoComplete="new-password"
                                    name="password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password2"
                                    label="Repita Senha"
                                    autoComplete="new-password"
                                    name="repetir password"
                                    type="password"
                                />
                            </Grid>
                            <Grid align="center" xs={12}>
                                <Grid item xs={12}>
                                    <Button onClick={handleOpen} className="termo">Clique aqui para ler o termo de
                                        uso</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Term/>
                                            <Stack>
                                                <Button variant="contained" onClick={handleClose}>Li o termo de
                                                    Uso</Button>
                                            </Stack>
                                        </Box>
                                    </Modal>
                                    <FormControlLabel control={<Checkbox required/>} label="Aceito termo de uso"
                                                      name="termo"/>
                                </Grid>
                                <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>Registrar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
        </Grid>
    )
};

export default Register;