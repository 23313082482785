import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import Term from "./Term";
import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getUser, updateUserUsageTerm} from "../../actions";


function Accept() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user, authorization} = useSelector((state) => state.userReducers);

  const handleCheckboxChange = async (event) => {
    await dispatch(updateUserUsageTerm(authorization.access_token, user.usuario_id, event.target.checked))
    await dispatch(getUser(authorization.access_token))
  }

  useEffect(() => {
    if (user.termo) {
      navigate("/match")
    }
  }, [dispatch, navigate, user.termo]);


  return (
    <Grid item xs={12}>
      <Term/>
      <FormControlLabel key="AcceptTerm" control={
        <Checkbox checked={user.termo}
                  onChange={handleCheckboxChange}/>}
                        label="Aceito termo de uso"/>
    </Grid>
  )
}

export default Accept;