import * as React from "react";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";

export const MarketMatchOddsTable = (props) => {

    const matchOddsColumns = [
        {
            field: 'mandante', headerName: "Mandante", width: 90, sortable: false,
            renderCell: (params) => {
                if (params.row.id === "prob") {
                    return params.value + "%"
                }
            }
        },
        {
            field: 'empate', headerName: "Empate", width: 90, sortable: false,
            renderCell: (params) => {
                if (params.row.id === "prob") {
                    return params.value + "%"
                }
            }
        },
        {
            field: 'visitante', headerName: "Visitante", width: 90, sortable: false,
            renderCell: (params) => {
                if (params.row.id === "prob") {
                    return params.value + "%"
                }
            }
        },
    ]

    const {rows} = props
    return (
        <div className="datagrid-container">
            <DataGrid
                disableColumnMenu
                style={{width: '270px'}}
                className="overUnder"
                getRowId={(row) => row.id}
                rows={rows}
                columns={matchOddsColumns}
                pageSize={25}
                autoHeight={true}
                rowHeight={25}
                headerHeight={25}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
            />
        </div>
    )
}

MarketMatchOddsTable.propTypes = {
    rows: PropTypes.array.isRequired
};