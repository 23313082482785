import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userReducers from "../reducers";

const rootReducer = combineReducers({
  counterReducer,
  userReducers,
});

export const store = configureStore({
  reducer: rootReducer,
});
