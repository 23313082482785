import React from 'react';
import {Button, Chip, Grid, Link, Typography} from "@mui/material";

export const SubscriptionCard = (props) => {
    const {sx, price, monthly, discount, discountColor, title} = props

    return (
        <Grid container sx={{...sx}}>
            {/*<Box sx={style} alignItems="stretch">*/}
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" align="left"
                            style={{fontWeight: '500', textAlign: 'center'}}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} marginTop={2}>
                {
                    discount &&
                    <Chip label={discount + "% de desconto!"} color={discountColor}/>
                }
                <Typography variant="h4" fontWeight="bold">
                    R$ {price}
                </Typography>
                <Typography component="h2" variant="subtitle2">
                    Cobrança {title}
                </Typography>
            </Grid>
            {
                monthly &&
                <Grid item xs={12} marginTop={2}>
                    <Typography component="h2" variant="subtitle2">
                        Equivalente á
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        R$ {monthly}<sup>/mês</sup>
                    </Typography>
                </Grid>
            }
            <Grid item xs={12} sx={{marginTop: 'auto'}}>
                <Button component={Link}
                        href="https://contate.me/prematch"
                        variant="contained"
                        target="_blank">
                    Fale conosco e assine!
                </Button>
            </Grid>
        </Grid>
    )
}
