import {Avatar, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export const DropDown = (props) => {
    return (
        <FormControl variant={props.variant} sx={props.sx} disabled={props.disabled} className='select'>
            <InputLabel id={props.labelId}>{props.label}</InputLabel>
            <Select
                labelId={props.labelId}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                label={props.label}
                name={props.name}
            >
                <MenuItem value="0">
                    <em>None</em>
                </MenuItem>

                {props.items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {!item.cleanName ? item.name :
                            <div>
                                <Avatar className="team-flag" src={`${item.image}`} variant="square"/>{item.cleanName}
                            </div>
                        }
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
