import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    changePassword,
    getCidades,
    getEstados,
    setAuthorization,
    setCidade,
    setEditedUserBairro,
    setEditedUserCep,
    setEditedUserComplemento,
    setEditedUserNumero,
    setEditedUserRua,
    setEditedUserTelefone,
    setEstado,
    updateUser
} from "../../actions";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {DropDown} from "../../components/common/DropDown";
import {toast} from "react-toastify";

export default function Profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formValues, setFormValues] = React.useState({});
    const {user, authorization, estados, cidades, estado, cidade} = useSelector((state) => state.userReducers);

    const handleChangePassword = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        if (data.get('new_password') !== data.get('repetir password')) {
            toast('As novas senhas devem ser iguais')
            return
        }
        let passwordChanged = await dispatch(changePassword(user.id, data, authorization.access_token))
            .catch(error => {
                toast.error(error.detail)
                navigate("/password")
            })
        if (passwordChanged) {
            toast.info("Senha trocada com sucesso!")
            navigate("/profile")
        }
    };

    const save = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        let object = {};
        data.forEach((value, key) => object[key] = value);
        let updated = await dispatch(updateUser(object)).then()
        if (updated) {
            toast.success("Perfil Atualizado!")
        }
    }

    const signOut = () => {
        localStorage.removeItem("token");
        dispatch(setAuthorization({
            access_token: "",
            token_type: "",
            roles: [],
        }))
        navigate("/");
    };

    const handleEstadoChange = (event) => {
        dispatch(setEstado(event.target.value));
        dispatch(getCidades(event.target.value)).then();
        const {value, name} = event.target;
        setFormValues({...formValues, [name]: value})
        dispatch(setCidade(""));
    };

    const handleCidadeChange = (event) => {
        dispatch(setCidade(event.target.value))
    };

    useEffect(() => {
        dispatch(getEstados()).then()
        dispatch(getCidades(user.estado))
        dispatch(setEstado(user.estado))
        dispatch(setCidade(user.cidade))
    }, [dispatch, navigate, user])

    const handleTelefoneChange = (event) => {
        setFormValues({...formValues, telefone: event.target.value})
        dispatch(setEditedUserTelefone(event.target.value))
    }

    const handleCepChange = (event) => {
        setFormValues({...formValues, cep: event.target.value})
        dispatch(setEditedUserCep(event.target.value))
    }

    const handleRuaChange = (event) => {
        setFormValues({...formValues, rua: event.target.value})
        dispatch(setEditedUserRua(event.target.value))
    }

    const handleNumeroChange = (event) => {
        setFormValues({...formValues, numero: event.target.value})
        dispatch(setEditedUserNumero(event.target.value))
    }

    const handleComplementoChange = (event) => {
        setFormValues({...formValues, complemento: event.target.value})
        dispatch(setEditedUserComplemento(event.target.value))
    }

    const handleBairroChange = (event) => {
        setFormValues({...formValues, bairro: event.target.value})
        dispatch(setEditedUserBairro(event.target.value))
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={0} md={1} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={5} lg={4}>
                <Box className='register-box' align='left'>
                    <Box component="form" onSubmit={save} sx={{mt: 3}}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5"
                                        style={{fontWeight: '500', marginBottom: '1rem'}}>
                                Perfil do Usuário
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Endereço de e-mail"
                                    autoComplete="email"
                                    name="email"
                                    value={user.email}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="fullName"
                                    label="Nome Completo"
                                    autoComplete="full-name"
                                    name="nome"
                                    value={user.nome}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="cpf"
                                    label="CPF"
                                    autoComplete="cpf"
                                    name="cpf"
                                    value={user.cpf}
                                    // onChange={handleCPF}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Telefone"
                                    autoComplete="tel"
                                    name="telefone"
                                    value={user.telefone}
                                    onChange={handleTelefoneChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h2" variant="h6">
                                    Endereço
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="cep"
                                    label="CEP"
                                    autoComplete="postal-code"
                                    name="cep"
                                    value={user.cep}
                                    onChange={handleCepChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="street"
                                    label="Rua"
                                    autoComplete="street-address1"
                                    name="rua"
                                    value={user.rua}
                                    onChange={handleRuaChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="number"
                                    label="Número"
                                    autoComplete="street-address2"
                                    name="numero"
                                    value={user.numero}
                                    onChange={handleNumeroChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="complemento"
                                    label="Complemento"
                                    autoComplete="street-address3"
                                    name="complemento"
                                    value={user.complemento}
                                    onChange={handleComplementoChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="neighborhood"
                                    label="Bairro"
                                    autoComplete="street-address3"
                                    name="bairro"
                                    value={user.bairro}
                                    onChange={handleBairroChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="register">
                                <DropDown
                                    labelId="estados-label"
                                    id="estados"
                                    onChange={handleEstadoChange}
                                    label="Estados"
                                    items={estados}
                                    variant="standard"
                                    name="estado"
                                    sx={{m: 1, minWidth: 120}}
                                    value={estado}
                                />
                            </Grid>
                            <Grid item xs={12} className="register">
                                <DropDown
                                    labelId="cidades-label"
                                    id="cidades"
                                    onChange={handleCidadeChange}
                                    label="Cidades"
                                    items={cidades}
                                    variant="standard"
                                    name="cidade"
                                    value={cidade}
                                    sx={{m: 1, minWidth: 120}}
                                />
                            </Grid>
                        </Grid>
                        <Box align="center">
                            <Grid item>
                                <Button type="submit" variant="contained"
                                        sx={{mt: 3, mb: 2, margin: '5px'}}>Atualizar</Button>
                                <Button variant="contained" sx={{mt: 3, mb: 2, margin: '5px'}}
                                        onClick={signOut}>Logout</Button>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4}>
                <Box className="login-box">
                    <Box component="form" onSubmit={handleChangePassword}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5"
                                            style={{fontWeight: '500', marginBottom: '1rem'}}>
                                    Trocar Senha
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    margin="normal"
                                    id="current_password"
                                    label="Senha Atual"
                                    autoComplete="current-password"
                                    name="password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    margin="normal"
                                    id="new_password"
                                    label="Nova Senha"
                                    autoComplete="new-password"
                                    name="new_password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    margin="normal"
                                    id="password2"
                                    label="Repita Nova Senha"
                                    autoComplete="new-password"
                                    name="repetir password"
                                    type="password"
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" sx={{mt: 3, mb: 2}}>Trocar Senha</Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={2}></Grid>
        </Grid>
    );
}
