import * as React from 'react';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Button, Chip, Grid, LinearProgress, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {MatchTable} from "./MatchTable";
import {EnhancedPoissonTable} from "./EnhacedTablePoisson";
import {EnhancedChanceTable} from "./EnhacedTableChance";
import {TableMinutes} from "./TableMinutes";
import {TableClassification} from "./TableClassification";
import {MatchesTable} from "./MatchesTable";
import {TableTypification} from "./TableTypification";
import {Responsive, WidthProvider} from "react-grid-layout";
import {
    getEditedMarketData,
    getHandcapMarket,
    resetHandcapMarket,
    setOddMandante,
    setOddVisitante,
    setOverMarket,
    setPrincipalGoalsConceded,
    setPrincipalGoalsScored,
    setTipificacaoJogo,
    setVisitorGoalsConceded,
    setVisitorGoalsScored
} from "../../actions";
import {MarketBttsTable} from "../market/MarketBttsTable";
import {MarketMatchOddsTable} from "../market/MarketMatchOddsTable";
import {MarketTeamStatisticsTable} from "../market/MarketTeamStatisticsTable";
import {MarketOverTable} from "../market/MarketOverTable";
import {MarketUnderTable} from "../market/MarketUnderTable";
import {MarketHandcapAsiaticoTable} from "../market/MarketHandcapAsiaticoTable";
import {Lock, LockOpen, RestartAlt} from "@mui/icons-material";
import {MarketTipificacaoTable} from "../market/MarketTipificacaoTable";
import {toast} from "react-toastify";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {/*<Typography>{children}</Typography>*/}
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
    const title_ft = "Full Time";
    const title_ht = "Half Time";
    const chance_title_ht = "Chance de Gols HT"
    const chance_title_ft = "Chance de Gols FT"
    const matches_title_principal = "Confrontos Mandante"
    const matches_title_visitor = "Confrontos Visitante"
    const classification_title_mandante = "Classificação Mandante"
    const classification_title_visitante = "Classificação Visitante"
    const classification_title_temporada = "Classificação Temporada"
    const HANDCAP_INITIAL = [{
        id: 'linha_000', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 0, linha_visitante: 0
    }, {
        id: 'linha_025', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 0.25, linha_visitante: -0.25
    }, {
        id: 'linha_050', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 0.50, linha_visitante: -0.50
    }, {
        id: 'linha_075', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 0.75, linha_visitante: -0.75
    }, {
        id: 'linha_100', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 1.00, linha_visitante: -1.00
    }, {
        id: 'linha_125', lado: 'mandante', odd_mandante: 0.0, odd_visitante: 0.0,
        chance_mandante: -100.0, chance_visitante: -100.0,
        linha_mandante: 1.25, linha_visitante: -1.25
    }]
    const LINHA_HANDCAP = {
        linha_000: 1,
        linha_025: 2,
        linha_050: 3,
        linha_075: 4,
        linha_100: 5,
        linha_125: 6
    }

    const [value, setValue] = React.useState(0);
    const [championshipTableValue, setChampionshipTableValue] = React.useState(0);
    const [isEditing, setIsEditing] = React.useState(false);
    const dispatch = useDispatch();
    const defaultLayouts = {
        version: 4,
        xl: [
            {w: 44, h: 5, x: 0, y: 0, i: "market-tipificacao", moved: false, static: false, isResizable: false},
            {w: 42, h: 4, x: 61, y: 0, i: "market-team-statistics", moved: false, static: false, isResizable: false},
            {w: 18, h: 3, x: 62, y: 4, i: "market-btts", moved: false, static: false, isResizable: false},
            {w: 14, h: 4, x: 62, y: 14, i: "market-match-odds", moved: false, static: false, isResizable: false},
            {w: 20, h: 8, x: 0, y: 5, i: "market-over", moved: false, static: false, isResizable: false},
            {w: 20, h: 8, x: 0, y: 13, i: "market-under", moved: false, static: false, isResizable: false},
            {w: 24, h: 7, x: 62, y: 7, i: "market-handcap-asiatico", moved: false, static: false, isResizable: false},
            {w: 14, h: 18, x: 34, y: 5, i: "market-poisson", moved: false, static: false, isResizable: false},
            {w: 14, h: 19, x: 20, y: 5, i: "market-score-frequency", moved: false, static: false, isResizable: false},
            {w: 14, h: 6, x: 48, y: 11, i: "market-chutes-a-gol", moved: false, static: false, isResizable: false},
            {w: 14, h: 6, x: 48, y: 16, i: "market-chutes-do-gol", moved: false, static: false, isResizable: false},
            {w: 14, h: 8, x: 48, y: 4, i: "market-cantos-ft", moved: false, static: false, isResizable: false}
        ],
        lg: [
            {w: 44, h: 5, x: 0, y: 0, i: "market-tipificacao", moved: false, static: false, isResizable: false},
            {w: 32, h: 4, x: 44, y: 0, i: "market-team-statistics", moved: false, static: false, isResizable: false},
            {w: 18, h: 3, x: 62, y: 4, i: "market-btts", moved: false, static: false, isResizable: false},
            {w: 14, h: 4, x: 62, y: 14, i: "market-match-odds", moved: false, static: false, isResizable: false},
            {w: 20, h: 8, x: 0, y: 5, i: "market-over", moved: false, static: false, isResizable: false},
            {w: 20, h: 8, x: 0, y: 13, i: "market-under", moved: false, static: false, isResizable: false},
            {w: 24, h: 7, x: 62, y: 7, i: "market-handcap-asiatico", moved: false, static: false, isResizable: false},
            {w: 14, h: 18, x: 34, y: 5, i: "market-poisson", moved: false, static: false, isResizable: false},
            {w: 14, h: 19, x: 20, y: 5, i: "market-score-frequency", moved: false, static: false, isResizable: false},
            {w: 14, h: 6, x: 48, y: 4, i: "market-chutes-a-gol", moved: false, static: false, isResizable: false},
            {w: 14, h: 6, x: 48, y: 11, i: "market-chutes-do-gol", moved: false, static: false, isResizable: false},
            {w: 14, h: 8, x: 48, y: 16, i: "market-cantos-ft", moved: false, static: false, isResizable: false}
        ],
        md: [
            {w: 25, h: 5, x: 0, y: 0, i: "market-tipificacao", moved: false, static: false, isResizable: false},
            {w: 18, h: 4, x: 25, y: 0, i: "market-team-statistics", moved: false, static: false, isResizable: false},
            {w: 10, h: 3, x: 35, y: 4, i: "market-btts", moved: false, static: false, isResizable: false},
            {w: 8, h: 4, x: 35, y: 14, i: "market-match-odds", moved: false, static: false, isResizable: false},
            {w: 11, h: 8, x: 0, y: 5, i: "market-over", moved: false, static: false, isResizable: false},
            {w: 11, h: 8, x: 0, y: 13, i: "market-under", moved: false, static: false, isResizable: false},
            {w: 14, h: 7, x: 35, y: 7, i: "market-handcap-asiatico", moved: false, static: false, isResizable: false},
            {w: 8, h: 18, x: 19, y: 5, i: "market-poisson", moved: false, static: false, isResizable: false},
            {w: 8, h: 19, x: 11, y: 5, i: "market-score-frequency", moved: false, static: false, isResizable: false},
            {w: 8, h: 6, x: 27, y: 4, i: "market-chutes-a-gol", moved: false, static: false, isResizable: false},
            {w: 8, h: 6, x: 27, y: 11, i: "market-chutes-do-gol", moved: false, static: false, isResizable: false},
            {w: 8, h: 8, x: 27, y: 16, i: "market-cantos-ft", moved: false, static: false, isResizable: false}
        ],
        sm: [
            {w: 29, h: 5, x: 0, y: 0, i: "market-tipificacao", moved: false, static: false, isResizable: false},
            {w: 21, h: 3, x: 0, y: 5, i: "market-team-statistics", moved: false, static: false, isResizable: false},
            {w: 12, h: 3, x: 0, y: 24, i: "market-btts", moved: false, static: false, isResizable: false},
            {w: 9, h: 4, x: 21, y: 5, i: "market-match-odds", moved: false, static: false, isResizable: false},
            {w: 13, h: 8, x: 0, y: 8, i: "market-over", moved: false, static: false, isResizable: false},
            {w: 13, h: 8, x: 0, y: 16, i: "market-under", moved: false, static: false, isResizable: false},
            {w: 16, h: 7, x: 13, y: 8, i: "market-handcap-asiatico", moved: false, static: false, isResizable: false},
            {w: 9, h: 18, x: 0, y: 33, i: "market-poisson", moved: false, static: false, isResizable: false},
            {w: 9, h: 19, x: 9, y: 33, i: "market-score-frequency", moved: false, static: false, isResizable: false},
            {w: 9, h: 6, x: 13, y: 24, i: "market-chutes-a-gol", moved: false, static: false, isResizable: false},
            {w: 9, h: 6, x: 22, y: 24, i: "market-chutes-do-gol", moved: false, static: false, isResizable: false},
            {w: 9, h: 8, x: 18, y: 28, i: "market-cantos-ft", moved: false, static: false, isResizable: false}
        ],
        xs: [
            {w: 24, h: 5, x: 0, y: 0, i: "market-tipificacao", moved: false, static: false, isResizable: false},
            {w: 24, h: 4, x: 0, y: 5, i: "market-team-statistics", moved: false, static: false, isResizable: false},
            {w: 24, h: 3, x: 0, y: 9, i: "market-btts", moved: false, static: false, isResizable: false},
            {w: 20, h: 4, x: 0, y: 12, i: "market-match-odds", moved: false, static: false, isResizable: false},
            {w: 24, h: 7, x: 0, y: 15, i: "market-over", moved: false, static: false, isResizable: false},
            {w: 24, h: 7, x: 0, y: 22, i: "market-under", moved: false, static: false, isResizable: false},
            {w: 16, h: 7, x: 0, y: 29, i: "market-handcap-asiatico", moved: false, static: false, isResizable: false},
            {w: 21, h: 18, x: 0, y: 54, i: "market-poisson", moved: false, static: false, isResizable: false},
            {w: 21, h: 19, x: 0, y: 72, i: "market-score-frequency", moved: false, static: false, isResizable: false},
            {w: 20, h: 6, x: 0, y: 41, i: "market-chutes-a-gol", moved: false, static: false, isResizable: false},
            {w: 20, h: 6, x: 0, y: 36, i: "market-chutes-do-gol", moved: false, static: false, isResizable: false},
            {w: 20, h: 8, x: 0, y: 46, i: "market-cantos-ft", moved: false, static: false, isResizable: false}
        ],
        xss: []
    }
    const userLayouts = JSON.parse(window.localStorage.getItem('savedLayout')) || defaultLayouts
    const [layout, setLayout] = React.useState(userLayouts);

    if (!layout.version || layout.version < defaultLayouts.version) {
        const hasDisplayedLayoutVersionMessage = sessionStorage.getItem('hasDisplayedLayoutVersionMessage');
        if (!hasDisplayedLayoutVersionMessage) {
            toast.warn("O Layout foi ajustado, pressione 'Reiniciar layout' para receber reajustes!")
            sessionStorage.setItem('hasDisplayedLayoutVersionMessage', 'true')
        }
    }

    const onLayoutChange = (currentLayout, allLayouts) => {
        setLayout(allLayouts);
        // Salvar layout no localstorage
        window.localStorage.setItem('savedLayout', JSON.stringify(allLayouts));
    }

    const resetLayout = () => {
        setLayout(defaultLayouts);
    }

    const {
        championship,
        principal,
        visitor,
        seasonTeams,
        principalData,
        visitorData,
        poisson_ft,
        poisson_ht,
        chance_ft,
        chance_ht,
        seasonStatistics,
        seasonScoreFrequency,
        oddMandante,
        oddVisitante,
        tipificacaoJogo,
        market,
        tables
    } = useSelector((state) => state.userReducers);

    const classification_home_columns = [
        // {heading: "P", alt: "Posição como Mandante", value: "leaguePosition_home", id: "leaguePosition_home"},
        {heading: "Mandante", alt: "Mandante", value: "cleanName", id: "cleanName"},
        {heading: "J", alt: "Jogos", value: "seasonMatchesPlayed_home", id: "seasonMatchesPlayed_home"},
        {heading: "PTS", alt: "Pontos", value: "points_home", id: "points_home"},
        {heading: "PPG", alt: "Pontos Por Jogo", value: "ppg_home", id: "ppg_home"},
        {heading: "V", alt: "Vitórias", value: "seasonWinsNum_home", id: "seasonWinsNum_home"},
        {heading: "E", alt: "Empates", value: "seasonDrawsNum_home", id: "seasonDrawsNum_home"},
        {heading: "D", alt: "Derrotas", value: "seasonLossesNum_home", id: "seasonLossesNum_home"},
        {heading: "GP", alt: "Gols Pró", value: "seasonScoredNum_home", id: "seasonScoredNum_home"},
        {heading: "GC", alt: "Gols Contra", value: "seasonConcededNum_home", id: "seasonConcededNum_home"},
        {heading: "MGP", alt: "Média Gols Pró", value: "seasonScoredAVG_home", id: "seasonScoredAVG_home"},
        {heading: "MGC", alt: "Média Gols Contra", value: "seasonConcededAVG_home", id: "seasonConcededAVG_home"},
        {heading: "CS", alt: "Clean Sheet", value: "seasonCSPercentage_home", id: "seasonCSPercentage_home"},
        {
            heading: "FTS",
            alt: "Falha em Marcar Gols",
            value: "seasonFTSPercentage_home",
            id: "seasonFTSPercentage_home"
        },
        {heading: "BTTS", alt: "Ambas Marcam", value: "seasonBTTSPercentage_home", id: "seasonBTTSPercentage_home"},
        {heading: "MCP", alt: "Média Cantos Pró", value: "cornersAVG_home", id: "cornersAVG_home"},
        {heading: "MCC", alt: "Média Cantos Contra", value: "cornersAgainstAVG_home", id: "cornersAgainstAVG_home"},
        {heading: "MCT", alt: "Média Cantos Total", value: "cornersTotalAVG_home", id: "cornersTotalAVG_home"},
        {heading: "GPHT", alt: "Gols Pró HT", value: "scoredGoalsHT_home", id: "scoredGoalsHT_home"},
        {heading: "GCHT", alt: "Gols Contra HT", value: "concededGoalsHT_home", id: "concededGoalsHT_home"},
        {heading: "MGPHT", alt: "Média Gols Pró HT", value: "scoredAVGHT_home", id: "scoredAVGHT_home"},
        {heading: "MGCHT", alt: "Média Gols Contra HT", value: "concededAVGHT_home", id: "concededAVGHT_home"},
        {
            heading: "MCPHT",
            alt: "Média Cantos Pró HT",
            value: "ht_media_cantos_a_favor_por_time_mandante",
            id: "ht_media_cantos_a_favor_por_time_mandante"
        },
        {
            heading: "MCCHT",
            alt: "Média Cantos Contra HT",
            value: "ht_media_cantos_contra_por_time_mandante",
            id: "ht_media_cantos_contra_por_time_mandante"
        },
        {
            heading: "MCTHT",
            alt: "Média Cantos Total HT",
            value: "ht_media_cantos_total_por_time_home",
            id: "ht_media_cantos_total_por_time_home"
        },
    ];

    const classification_away_columns = [
        // {heading: "P", alt: "Posição como Visitante", value: "leaguePosition_away", id: "leaguePosition_away"},
        {heading: "Visitante", alt: "Visitante", value: "cleanName", id: "cleanName"},
        {heading: "J", alt: "Jogos", value: "seasonMatchesPlayed_away", id: "seasonMatchesPlayed_away"},
        {heading: "PTS", alt: "Pontos", value: "points_away", id: "points_away"},
        {heading: "PPG", alt: "Pontos Por Jogo", value: "ppg_away", id: "ppg_away"},
        {heading: "V", alt: "Vitórias", value: "seasonWinsNum_away", id: "seasonWinsNum_away"},
        {heading: "E", alt: "Empates", value: "seasonDrawsNum_away", id: "seasonDrawsNum_away"},
        {heading: "D", alt: "Derrotas", value: "seasonLossesNum_away", id: "seasonLossesNum_away"},
        {heading: "GP", alt: "Gols Pró", value: "seasonScoredNum_away", id: "seasonScoredNum_away"},
        {heading: "GC", alt: "Gols Contra", value: "seasonConcededNum_away", id: "seasonConcededNum_away"},
        {heading: "MGP", alt: "Média Gols Pró", value: "seasonScoredAVG_away", id: "seasonScoredAVG_away"},
        {heading: "MGC", alt: "Média Gols Contra", value: "seasonConcededAVG_away", id: "seasonConcededAVG_away"},
        {heading: "CS", alt: "Clean Sheet", value: "seasonCSPercentage_away", id: "seasonCSPercentage_away"},
        {
            heading: "FTS",
            alt: "Falha em Marcar Gols",
            value: "seasonFTSPercentage_away",
            id: "seasonFTSPercentage_away"
        },
        {heading: "BTTS", alt: "Ambas Marcam", value: "seasonBTTSPercentage_away", id: "seasonBTTSPercentage_away"},
        {heading: "MCP", alt: "Média Cantos Pró", value: "cornersAVG_away", id: "cornersAVG_away"},
        {heading: "MCC", alt: "Média Cantos Contra", value: "cornersAgainstAVG_away", id: "cornersAgainstAVG_away"},
        {heading: "MCT", alt: "Média Cantos Total", value: "cornersTotalAVG_away", id: "cornersTotalAVG_away"},
        {heading: "GPHT", alt: "Gols Pró HT", value: "scoredGoalsHT_away", id: "scoredGoalsHT_away"},
        {heading: "GCHT", alt: "Gols Contra HT", value: "concededGoalsHT_away", id: "concededGoalsHT_away"},
        {heading: "MGPHT", alt: "Média Gols Pró HT", value: "scoredAVGHT_away", id: "scoredAVGHT_away"},
        {heading: "MGCHT", alt: "Média Gols Contra HT", value: "concededAVGHT_away", id: "concededAVGHT_away"},
        {
            heading: "MCPHT",
            alt: "Média Cantos Pró HT",
            value: "ht_media_cantos_a_favor_por_time_visitante",
            id: "ht_media_cantos_a_favor_por_time_visitante"
        },
        {
            heading: "MCCHT",
            alt: "Média Cantos Contra HT",
            value: "ht_media_cantos_contra_por_time_visitante",
            id: "ht_media_cantos_contra_por_time_visitante"
        },
        {
            heading: "MCTHT",
            alt: "Média Cantos Total HT",
            value: "ht_media_cantos_total_por_time_away",
            id: "ht_media_cantos_total_por_time_away"
        },
    ];

    const classification_season_columns = [
        // {heading: "P", alt: "Posição na Temporada", value: "leaguePosition_overall", id: "leaguePosition_overall"},
        {heading: "Temporada", alt: "Temporada", value: "cleanName", id: "cleanName"},
        {heading: "J", alt: "Jogos", value: "seasonMatchesPlayed_overall", id: "seasonMatchesPlayed_overall"},
        {heading: "PTS", alt: "Pontos", value: "points_overall", id: "points_overall"},
        {heading: "PPG", alt: "Pontos Por Jogo", value: "ppg_overall", id: "ppg_overall"},
        {heading: "V", alt: "Vitórias", value: "seasonWinsNum_overall", id: "seasonWinsNum_overall"},
        {heading: "E", alt: "Empates", value: "seasonDrawsNum_overall", id: "seasonDrawsNum_overall"},
        {heading: "D", alt: "Derrotas", value: "seasonLossesNum_overall", id: "seasonLossesNum_overall"},
        {heading: "GP", alt: "Gols Pró", value: "seasonScoredNum_overall", id: "seasonScoredNum_overall"},
        {heading: "GC", alt: "Gols Contra", value: "seasonConcededNum_overall", id: "seasonConcededNum_overall"},
        {heading: "MGP", alt: "Média Gols Pró", value: "seasonScoredAVG_overall", id: "seasonScoredAVG_overall"},
        {heading: "MGC", alt: "Média Gols Contra", value: "seasonConcededAVG_overall", id: "seasonConcededAVG_overall"},
        {heading: "CS", alt: "Clean Sheet", value: "seasonCSPercentage_overall", id: "seasonCSPercentage_overall"},
        {
            heading: "FTS",
            alt: "Falha em Marcar Gols",
            value: "seasonFTSPercentage_overall",
            id: "seasonFTSPercentage_overall"
        },
        {
            heading: "BTTS",
            alt: "Ambas Marcam",
            value: "seasonBTTSPercentage_overall",
            id: "seasonBTTSPercentage_overall"
        },
        {heading: "MCP", alt: "Média Cantos Pró", value: "cornersAVG_overall", id: "cornersAVG_overall"},
        {
            heading: "MCC",
            alt: "Média Cantos Contra",
            value: "cornersAgainstAVG_overall",
            id: "cornersAgainstAVG_overall"
        },
        {heading: "MCT", alt: "Média Cantos Total", value: "cornersTotalAVG_overall", id: "cornersTotalAVG_overall"},
        {heading: "GPHT", alt: "Gols Pró HT", value: "scoredGoalsHT_overall", id: "scoredGoalsHT_overall"},
        {heading: "GCHT", alt: "Gols Contra HT", value: "concededGoalsHT_overall", id: "concededGoalsHT_overall"},
        {heading: "MGPHT", alt: "Média Gols Pró HT", value: "scoredAVGHT_overall", id: "scoredAVGHT_overall"},
        {heading: "MGCHT", alt: "Média Gols Contra HT", value: "concededAVGHT_overall", id: "concededAVGHT_overall"},
        {
            heading: "MCPHT",
            alt: "Média Cantos Pró HT",
            value: "ht_media_cantos_a_favor_por_time_overall",
            id: "ht_media_cantos_a_favor_por_time_overall"
        },
        {
            heading: "MCCHT",
            alt: "Média Cantos Contra HT",
            value: "ht_media_cantos_contra_por_time_overall",
            id: "ht_media_cantos_contra_por_time_overall"
        },
        {
            heading: "MCTHT",
            alt: "Média Cantos Total HT",
            value: "ht_media_cantos_por_time_overall",
            id: "ht_media_cantos_por_time_overall"
        },
    ];

    const classification_table_columns = [
        // {heading: "P", alt: "Posição na Temporada", value: "position", id: "leaguePosition_overall"},
        {heading: "Temporada", alt: "Temporada", value: "cleanName", id: "cleanName"},
        {heading: "J", alt: "Jogos", value: "matchesPlayed", id: "matchesPlayed"},
        {heading: "PTS", alt: "Pontos", value: "points", id: "points"},
        {heading: "PPG", alt: "Pontos Por Jogo", value: "pointsAVG", id: "pointsAVG"},
        {heading: "V", alt: "Vitórias", value: "seasonWins_overall", id: "seasonWins_overall"},
        {heading: "E", alt: "Empates", value: "seasonDraws_overall", id: "seasonDraws_overall"},
        {heading: "D", alt: "Derrotas", value: "seasonLosses_overall", id: "seasonLosses_overall"},
        {heading: "GP", alt: "Gols Pró", value: "seasonGoals", id: "seasonGoals"},
        {heading: "GC", alt: "Gols Contra", value: "seasonConceded", id: "seasonConceded"},
        {heading: "MGP", alt: "Média Gols Pró", value: "seasonScoredAVG", id: "seasonScoredAVG"},
        {heading: "MGC", alt: "Média Gols Contra", value: "seasonConcededAVG", id: "seasonConcededAVG"},
        {heading: "CS", alt: "Clean Sheet", value: "cleansheetPercentage", id: "cleansheetPercentage"},
        {
            heading: "FTS",
            alt: "Falha em Marcar Gols",
            value: "ftsPercentage",
            id: "seasonFTSPercentage_overall"
        },
        {
            heading: "BTTS",
            alt: "Ambas Marcam",
            value: "bttsPercentage",
            id: "seasonBTTSPercentage_overall"
        },
        {heading: "MCP", alt: "Média Cantos Pró", value: "cantosAVG", id: "cornersAVG_overall"},
        {
            heading: "MCC",
            alt: "Média Cantos Contra",
            value: "cantosContraAVG",
            id: "cornersAgainstAVG_overall"
        },
        {heading: "MCT", alt: "Média Cantos Total", value: "cantosTotal", id: "cornersTotalAVG_overall"},
        {heading: "GPHT", alt: "Gols Pró HT", value: "seasonGoalsHT", id: "scoredGoalsHT_overall"},
        {heading: "GCHT", alt: "Gols Contra HT", value: "seasonConcededHT", id: "concededGoalsHT_overall"},
        {heading: "MGPHT", alt: "Média Gols Pró HT", value: "seasonScoredHTAVG", id: "scoredAVGHT_overall"},
        {heading: "MGCHT", alt: "Média Gols Contra HT", value: "seasonConcededHTAVG", id: "concededAVGHT_overall"},
        {
            heading: "MCPHT",
            alt: "Média Cantos Pró HT",
            value: "CantosHTAVG",
            id: "ht_media_cantos_a_favor_por_time_overall"
        },
        {
            heading: "MCCHT",
            alt: "Média Cantos Contra HT",
            value: "CantosContraHTAVG",
            id: "ht_media_cantos_contra_por_time_overall"
        },
        {
            heading: "MCTHT",
            alt: "Média Cantos Total HT",
            value: "CantosTotalHTAVG",
            id: "ht_media_cantos_por_time_overall"
        },
    ];

    const chance_ft_columns = [
        {
            heading: "Gols",
            value: "goals",
            tag: "goals-principal",
            id: 'goals',
            numeric: true,
            disablePadding: true,
            label: 'Gols'
        },
        {
            heading: "Chance",
            value: "principal",
            tag: "principal",
            id: 'principal',
            numeric: true,
            disablePadding: true,
            label: 'Chance'
        },
        {
            heading: "Chance",
            value: "visitor",
            tag: "visitor",
            id: 'visitor',
            numeric: true,
            disablePadding: true,
            label: 'Chance'
        },
        {
            heading: "Gols",
            value: "goals",
            tag: "goals-visitor",
            id: 'goals-visitor',
            numeric: true,
            disablePadding: true,
            label: 'Gols'
        },
    ];

    const chance_ht_columns = [
        {
            heading: "Gols",
            value: "goals",
            tag: "goals-principal",
            id: 'goals',
            numeric: true,
            disablePadding: true,
            label: 'Gols'
        },
        {
            heading: "Chance",
            value: "principal",
            tag: "principal",
            id: 'principal',
            numeric: true,
            disablePadding: true,
            label: 'Chance'
        },
        {
            heading: "Chance",
            value: "visitor",
            tag: "visitor",
            id: 'visitor',
            numeric: true,
            disablePadding: true,
            label: 'Chance'
        },
        {
            heading: "Gols",
            value: "goals",
            tag: "goals-visitor",
            id: 'goals-visitor',
            numeric: true,
            disablePadding: true,
            label: 'Gols'
        },
    ];


    const matches_ft_columns = [
        {
            heading: "Mandante",
            value: "principal",
            tag: "time-mandante",
            id: "mandante",
            numeric: false,
            disablePadding: true,
            label: "Mandante"
        },
        // {
        //   heading: "Gols",
        //   value: "goals",
        //   tag: "goals-principal",
        //   id: 'goals-principal',
        //   numeric: true,
        //   disablePadding: true,
        //   label: 'Gols'
        // },
        // {
        //   heading: "Gols",
        //   value: "goals",
        //   tag: "goals-visitor",
        //   id: 'goals-visitor',
        //   numeric: true,
        //   disablePadding: true,
        //   label: 'Gols'
        // },
        {
            heading: "Placar",
            value: "placar",
            tag: "placar",
            id: 'placar',
            numeric: false,
            disablePadding: true,
            label: 'Placar'
        },
        {
            heading: "Visitante",
            value: "visitorx",
            tag: "time-visitante",
            id: "visitante",
            numeric: false,
            disablePadding: true,
            label: "Visitante"
        },
    ];

    const poisson_ft_columns = [
        {heading: "Placar", value: "goals", id: 'goals', numeric: false, disablePadding: true, label: 'Placar'},
        {heading: "Prob %", value: "prob", id: 'prob', numeric: true, disablePadding: true, label: 'Prob %'},
        {heading: "Odd Justa", value: "odd", id: 'odd', numeric: true, disablePadding: true, label: 'Odd Justa'},
    ];

    const poisson_ht_columns = [
        {heading: "Placar", value: "goals", id: 'goals', numeric: false, disablePadding: true, label: 'Placar'},
        {heading: "Prob %", value: "prob", id: 'prob', numeric: true, disablePadding: true, label: 'Prob %'},
        {heading: "Odd Justa", value: "odd", id: 'odd', numeric: true, disablePadding: true, label: 'Odd Justa'},
    ];

    const gols_pro_ft_columns = [
        {heading: "Média GP", value: "media_time", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_time", tipo: 'gols'},
        {heading: "VAR", value: "media_var_time", tipo: 'gols'},
        {heading: "Liga GP", value: "media_geral", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_var", tipo: 'gols'},
        // {heading: "Var", value: "media_var_geral", tipo: 'gols'},
    ];

    const gols_pro_ht_columns = [
        {heading: "Média GP", value: "media_time", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_time", tipo: 'gols'},
        {heading: "VAR", value: "media_var_time", tipo: 'gols'},
        {heading: "Liga GP", value: "media_geral", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_var", tipo: 'gols'},
        // {heading: "Var", value: "media_var_geral", tipo: 'gols'},
    ];

    const gols_contra_ft_columns = [
        {heading: "Média GC", value: "media_time", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_time", tipo: 'gols'},
        {heading: "VAR", value: "media_var_time", tipo: 'gols'},
        {heading: "Liga GC", value: "media_geral", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_var", tipo: 'gols'},
        // {heading: "Var", value: "media_var_geral", tipo: 'gols'},
    ];

    const gols_contra_ht_columns = [
        {heading: "Média GC", value: "media_time", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_time", tipo: 'gols'},
        {heading: "VAR", value: "media_var_time", tipo: 'gols'},
        {heading: "Liga GC", value: "media_geral", tipo: 'gols'},
        // {heading: "Ranking", value: "ranking_var", tipo: 'gols'},
        // {heading: "Var", value: "media_var_geral", tipo: 'gols'},
    ];

    const gols_minutagem_ht_columns = [
        {
            minutes: "0-15", heading: "0-15",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "16-30", heading: "16-30",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "31-45", heading: "31-45",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "46-60", heading: "46-60",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "61-75", heading: "61-75",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "76-90", heading: "76-90",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "0-45", heading: "1° tempo",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "46-90", heading: "2° tempo",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
    ]

    const gols_minutagem_ft_columns = [
        {
            minutes: "0-15", heading: "0-15",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "16-30", heading: "16-30",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "31-45", heading: "31-45",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "46-60", heading: "46-60",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "61-75", heading: "61-75",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "76-90", heading: "76-90",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "0-45", heading: "1° tempo",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
        {
            minutes: "46-90", heading: "2° tempo",
            marcados: {value: "marcados", tipo: 'gols'},
            marcados_chance: {value: "marcados_chance", tipo: 'prob'},
            sofridos: {value: "sofridos", tipo: 'gols'},
            sofridos_chance: {value: "sofridos_chance", tipo: 'prob'}
        },
    ]

    const btts_ft_columns = [
        {heading: "Casa", value: "casa", tipo: 'btts'},
        // {heading: "Ranking", value: "ranking", tipo: 'btts'},
        {heading: "Liga", value: "media_geral", tipo: 'btts'},
        // {heading: "Linear", value: "linear", tipo: 'btts'},
        // {heading: "Ranking", value: "ranking_linear", tipo: 'btts'},
    ];

    const btts_ht_columns = [
        {heading: "Casa", value: "casa", tipo: 'btts'},
        // {heading: "Ranking", value: "ranking", tipo: 'btts'},
        {heading: "Liga", value: "media_geral", tipo: 'btts'},
    ];

    const chutes_a_gol = [
        // {heading: "Chutes", value: "chutes", tipo: 'chutes'},
        // {heading: "Ranking", value: "ranking", tipo: 'chutes'},
        {heading: "Média", value: "media_chutes", tipo: 'chutes'},
        // {heading: "RK Média", value: "ranking_media", tipo: 'chutes'},
        // {heading: "Liga", value: "liga", tipo: 'chutes'},
        {heading: "Média Liga", value: "media_liga", tipo: 'chutes'},
    ];

    const chutes_no_gol = [
        // {heading: "Chutes", value: "chutes", tipo: 'chutes'},
        // {heading: "Ranking", value: "ranking", tipo: 'chutes'},
        {heading: "Média", value: "media_chutes", tipo: 'chutes'},
        // {heading: "RK Média", value: "ranking_media", tipo: 'chutes'},
        // {heading: "Liga", value: "liga", tipo: 'chutes'},
        {heading: "Média Liga", value: "media_liga", tipo: 'chutes'},
    ];

    const cantos_ht_columns = [
        // {heading: "CP", value: "cantos_pro", tipo: 'cantos'},
        {heading: "Média CP", value: "media_cantos_pro", tipo: 'cantos'},
        // {heading: "CC", value: "cantos_contra", tipo: 'cantos'},
        {heading: "Média CC", value: "media_cantos_contra", tipo: 'cantos'},
        {heading: "Média CT", value: "media_cantos_total", tipo: 'cantos'},
        {heading: "Media JG", value: "media_cantos_partida", tipo: 'cantos'},
        {heading: "Media Liga", value: "media_cantos_liga_total", tipo: 'cantos'}
    ];
    const cantos_ft_columns = [
        // {heading: "CP", value: "cantos_pro", tipo: 'cantos'},
        {heading: "Média CP", value: "media_cantos_pro", tipo: 'cantos'},
        // {heading: "CC", value: "cantos_contra", tipo: 'cantos'},
        {heading: "Média CC", value: "media_cantos_contra", tipo: 'cantos'},
        {heading: "Média CT", value: "media_cantos_total", tipo: 'cantos'},
        {heading: "Media JG", value: "media_cantos_partida", tipo: 'cantos'},
        {heading: "Media Liga", value: "media_cantos_liga_total", tipo: 'cantos'}
    ];

    const typification_columns = [
        {heading: "Tipo", value: "tipificacao", tipo: 'text', alt: "Nomenclatura do tipo de jogo", id: "tipificacao"},
        {heading: "Jogos", value: "jogos", tipo: 'int', alt: "Número de partidas", id: "jogos"},
        {
            heading: "% jogos",
            value: "porcentagem_jogos",
            tipo: '%',
            alt: "Porcentagem do tipo de jogo em relação ao total de partidas",
            id: "porcentagem_jogos"
        },
        {heading: "Pontos", value: "pontos", tipo: 'int', alt: "Pontos ganhos", id: "pontos"},
        {
            heading: "PPG",
            value: "pontos_por_jogo",
            tipo: 'float',
            alt: "Média de pontos ganhos por partida",
            id: "pontos_por_jogo"
        },
        {heading: "V", value: "vitorias", tipo: 'int', alt: "Número de vitórias", id: "vitorias"},
        {heading: "E", value: "empates", tipo: 'int', alt: "Número de empates", id: "empates"},
        {heading: "D", value: "derrotas", tipo: 'int', alt: "Número de derrotas", id: "derrotas"},
        {heading: "GP", value: "gols_pro", tipo: 'int', alt: "Número de gols a favor", id: "gols_pro"},
        {heading: "GC", value: "gols_contra", tipo: 'int', alt: "Número de gols contra", id: "gols_contra"},
        {heading: "MGP", value: "media_gols_pro", tipo: 'float', alt: "Média de gols a favor", id: "media_gols_pro"},
        {
            heading: "MGC",
            value: "media_gols_contra",
            tipo: 'float',
            alt: "Méida de gols contra",
            id: "media_gols_contra"
        },
        {
            heading: "CS",
            value: "cleansheet_porcentagem",
            tipo: '%',
            alt: "'CLean Sheet' - Porcentagem de partidas sem sofre gols",
            id: "cleansheet_porcentagem"
        },
        {
            heading: "FTS",
            value: "fts_porcentagem",
            tipo: '%',
            alt: "'Fail To Score' - Porcentagem de partidas sem marcar gols",
            id: "fts_porcentagem"
        },
        {
            heading: "BTTS",
            value: "btts_porcentagem",
            tipo: '%',
            alt: "'Both Teams To Score - Porcentagem de partidas em que as duas equipes marcaram gols",
            id: "btts_porcentagem"
        },
        {
            heading: "MCP",
            value: "media_cantos_a_favor",
            tipo: 'float',
            alt: "Médias de escanteios a favor",
            id: "media_cantos_a_favor"
        },
        {heading: "MCC", value: "media_cantos_contra", tipo: 'float', alt: "Méida de escanteios contra"},
        {
            heading: "MCT",
            value: "media_cantos_total",
            tipo: 'float',
            alt: "Média de escanteios totais (a favor e contra)",
            id: "media_cantos_total"
        },
        {
            heading: "GPHT",
            value: "gols_pro_ht",
            tipo: 'number',
            alt: "Número de gols a favor no primeiro tempo",
            id: "gols_pro_ht"
        },
        {
            heading: "GCHT",
            value: "gols_contra_ht",
            tipo: 'number',
            alt: "Número de gols contra no primeiro tempo",
            id: "gols_contra_ht"
        },
        {
            heading: "MGPHT",
            value: "media_gols_pro_ht",
            tipo: 'float',
            alt: "Média de gols a favor no primeiro tempo",
            id: "media_gols_pro_ht"
        },
        {
            heading: "MGCHT",
            value: "media_gols_contra_ht",
            tipo: 'float',
            alt: "Média de gols contra no primeiro tempo",
            id: "media_gols_contra_ht"
        },
    ]

    const acumulated_typification_columns = [
        {heading: "Ref", value: "ref", tipo: 'text', alt: "Referência ao time do confronto", id: 'ref'},
        {heading: "Tipo", value: "tipificacao", tipo: 'text', alt: "Nomenclatura do tipo de jogo", id: "tipificacao"},
        {heading: "Jogos", value: "jogos", tipo: 'int', alt: "Número de partidas", id: "jogos"},
        {
            heading: "% jogos",
            value: "porcentagem_jogos",
            tipo: '%',
            alt: "Porcentagem do tipo de jogo em relação ao total de partidas",
            id: "porcentagem_jogos"
        },
        {heading: "Pontos", value: "pontos", tipo: 'int', alt: "Pontos ganhos", id: "pontos"},
        {
            heading: "PPG",
            value: "pontos_por_jogo",
            tipo: 'float',
            alt: "Média de pontos ganhos por partida",
            id: "pontos_por_jogo"
        },
        {heading: "V", value: "vitorias", tipo: 'int', alt: "Número de vitórias", id: "vitorias"},
        {heading: "E", value: "empates", tipo: 'int', alt: "Número de empates", id: "empates"},
        {heading: "D", value: "derrotas", tipo: 'int', alt: "Número de derrotas", id: "derrotas"},
        {heading: "GP", value: "gols_pro", tipo: 'int', alt: "Número de gols a favor", id: "gols_pro"},
        {heading: "GC", value: "gols_contra", tipo: 'int', alt: "Número de gols contra", id: "gols_contra"},
        {heading: "MGP", value: "media_gols_pro", tipo: 'float', alt: "Média de gols a favor", id: "media_gols_pro"},
        {
            heading: "MGC",
            value: "media_gols_contra",
            tipo: 'float',
            alt: "Méida de gols contra",
            id: "media_gols_contra"
        },
        {
            heading: "CS",
            value: "cleansheet_porcentagem",
            tipo: '%',
            alt: "'CLean Sheet' - Porcentagem de partidas sem sofre gols",
            id: "cleansheet_porcentagem"
        },
        {
            heading: "FTS",
            value: "fts_porcentagem",
            tipo: '%',
            alt: "'Fail To Score' - Porcentagem de partidas sem marcar gols",
            id: "fts_porcentagem"
        },
        {
            heading: "BTTS",
            value: "btts_porcentagem",
            tipo: '%',
            alt: "'Both Teams To Score - Porcentagem de partidas em que as duas equipes marcaram gols",
            id: "btts_porcentagem"
        },
        {
            heading: "MCP",
            value: "media_cantos_a_favor",
            tipo: 'float',
            alt: "Médias de escanteios a favor",
            id: "media_cantos_a_favor"
        },
        {heading: "MCC", value: "media_cantos_contra", tipo: 'float', alt: "Méida de escanteios contra"},
        {
            heading: "MCT",
            value: "media_cantos_total",
            tipo: 'float',
            alt: "Média de escanteios totais (a favor e contra)",
            id: "media_cantos_total"
        },
        {
            heading: "GPHT",
            value: "gols_pro_ht",
            tipo: 'number',
            alt: "Número de gols a favor no primeiro tempo",
            id: "gols_pro_ht"
        },
        {
            heading: "GCHT",
            value: "gols_contra_ht",
            tipo: 'number',
            alt: "Número de gols contra no primeiro tempo",
            id: "gols_contra_ht"
        },
        {
            heading: "MGPHT",
            value: "media_gols_pro_ht",
            tipo: 'float',
            alt: "Média de gols a favor no primeiro tempo",
            id: "media_gols_pro_ht"
        },
        {
            heading: "MGCHT",
            value: "media_gols_contra_ht",
            tipo: 'float',
            alt: "Média de gols contra no primeiro tempo",
            id: "media_gols_contra_ht"
        },
    ]

    const score_frequency_columns = [
        {heading: "Placar", value: "placar", id: 'placar', numeric: false, disablePadding: true, label: 'Placar'},
        {heading: "Jogos", value: "jogos", id: 'jogos', numeric: false, disablePadding: true, label: 'Jogos'},
        {heading: "Prob %", value: "prob", id: 'prob', numeric: true, disablePadding: true, label: 'Prob %'},
        {heading: "Odd Justa", value: "odd", id: 'odd', numeric: true, disablePadding: true, label: 'Odd Justa'},
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChampionshipTableChange = (event, newValue) => {
        setChampionshipTableValue(newValue)
    }

    const normalise = (value, max) => ((value - 0) * 100) / (max - 0)

    const handleOddMandanteChange = async (event) => {
        await dispatch(setOddMandante(parseFloat(event.target.value)))
        calcularTipoConfronto(event.target.value, oddVisitante)
        await dispatch(resetHandcapMarket(HANDCAP_INITIAL))
    }

    const handleOddVisitanteChange = async (event) => {
        await dispatch(setOddVisitante(parseFloat(event.target.value)))
        calcularTipoConfronto(oddMandante, event.target.value)
        await dispatch(resetHandcapMarket(HANDCAP_INITIAL))
    }

    const handleBttsCommited = ({id, field, value}) => {
        if (!isNaN(parseFloat(value)) && value > 0) {
            dispatch(getEditedMarketData(
                championship, principal, visitor, principalData.jogos, principalData.gols_pro_ft.qtd,
                principalData.gols_contra_ft.qtd, principalData.gols_pro_ft.media_geral, visitorData.jogos,
                visitorData.gols_pro_ft.qtd, visitorData.gols_contra_ft.qtd, visitorData.gols_pro_ft.media_geral, value))
        }
    }

    const handleTeamStatisticsCommited = ({id, field, value}) => {
        if (field === 'gols-pro') {
            if (id === 'Mandante') {
                dispatch(setPrincipalGoalsScored(value))
                dispatch(getEditedMarketData(
                    championship, principal, visitor, principalData.jogos, value,
                    principalData.gols_contra_ft.qtd, principalData.gols_pro_ft.media_geral, visitorData.jogos,
                    visitorData.gols_pro_ft.qtd, visitorData.gols_contra_ft.qtd, visitorData.gols_pro_ft.media_geral,
                    market.btts_ft[0].odd))
            } else if (id === 'Visitante') {
                dispatch(setVisitorGoalsScored(value))
                dispatch(getEditedMarketData(
                    championship, principal, visitor, principalData.jogos, principalData.gols_pro_ft.qtd,
                    principalData.gols_contra_ft.qtd, principalData.gols_pro_ft.media_geral, visitorData.jogos,
                    value, visitorData.gols_contra_ft.qtd, visitorData.gols_pro_ft.media_geral,
                    market.btts_ft[0].odd))
            }
        } else if (field === 'gols-contra') {
            if (id === 'Mandante') {
                dispatch(setPrincipalGoalsConceded(value))
                dispatch(getEditedMarketData(
                    championship, principal, visitor, principalData.jogos, principalData.gols_pro_ft.qtd,
                    value, principalData.gols_pro_ft.media_geral, visitorData.jogos,
                    visitorData.gols_pro_ft.qtd, visitorData.gols_contra_ft.qtd, visitorData.gols_pro_ft.media_geral,
                    market.btts_ft[0].odd)).then()
            } else if (id === 'Visitante') {
                dispatch(setVisitorGoalsConceded(value))
                dispatch(getEditedMarketData(
                    championship, principal, visitor, principalData.jogos, principalData.gols_pro_ft.qtd,
                    principalData.gols_contra_ft.qtd, principalData.gols_pro_ft.media_geral, visitorData.jogos,
                    visitorData.gols_pro_ft.qtd, value, visitorData.gols_pro_ft.media_geral,
                    market.btts_ft[0].odd)).then()
            }
        }
    }

    const handleEditOverUnderCommited = ({id, field, value}) => {
        if (!isNaN(parseFloat(value)) && value > 0) {
            dispatch(setOverMarket({key: id, field: field, odd: value}))
        }
    };

    const handleHandcapCommited = ({id, field, value}) => {
        let linha = LINHA_HANDCAP[id]
        if (!isNaN(parseFloat(value)) && value > 0) {
            if (field === 'odd_mandante') {
                dispatch(getHandcapMarket(championship, principal, visitor, 'mandante', oddMandante, oddVisitante, linha, value !== '' ? value : 0))

            }

            if (field === 'odd_visitante') {
                dispatch(getHandcapMarket(championship, principal, visitor, 'visitante', oddMandante, oddVisitante, linha, value !== '' ? value : 0))

            }
        }
    };

    const calcularTipoConfronto = (om, ov) => {
        if (om < 1.41) {
            dispatch(setTipificacaoJogo("SFM"))
        } else if (om > 1.40 && om < 1.81) {
            dispatch(setTipificacaoJogo("FM"))
        } else if (om > 1.80 && om < 2.21) {
            dispatch(setTipificacaoJogo("MFM"))
        } else if (ov < 1.41) {
            dispatch(setTipificacaoJogo("SFV"))
        } else if (ov > 1.40 && ov < 1.81) {
            dispatch(setTipificacaoJogo("FV"))
        } else if (ov > 1.80 && ov < 2.21) {
            dispatch(setTipificacaoJogo("MFV"))
        } else if (om > 2.20 & ov > 2.20) {
            dispatch(setTipificacaoJogo("EQ"))
        }
    }
    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="match tab" centered className='match-tabs'
                      textColor="secondary">
                    <Tab label="Halftime" {...a11yProps(0)} />
                    <Tab label="Fulltime" {...a11yProps(1)} />
                    <Tab label="Classificação" {...a11yProps(2)} />
                    <Tab label="Tipificação" {...a11yProps(3)} />
                    <Tab label="EFR" {...a11yProps(4)} />
                    <Tab label="Mercados" {...a11yProps(5)} />
                    <Button title={"Modificar dashboard: " + (isEditing ? "Liberado!" : "Bloqueado!")}
                            onClick={() => setIsEditing(!isEditing)}>
                        {isEditing ? <LockOpen title="Drag & Drop liberado."/> :
                            <Lock title="Drag & Drop bloqueado."/>}</Button>
                    <Button title="Reiniciar Layout" onClick={resetLayout}>
                        <RestartAlt title="Reiniciar Layout"/>
                    </Button>
                </Tabs>
                <Box sx={{display: 'flex', alignItems: 'center', paddingLeft: "10px"}}>
                    <Typography variant="body2" color="white !important">Jogos</Typography>
                    <Box sx={{width: '100%', mr: 1, paddingRight: "10px", paddingLeft: "10px"}}>
                        <LinearProgress className="championship-progress-bar" color="success" variant="determinate"
                                        value={normalise(seasonStatistics.matchesCompleted, seasonStatistics.totalMatches)}/>
                    </Box>
                    <Box sx={{whiteSpace: 'nowrap', paddingRight: "10px"}}>
                        <Typography variant="body2"
                                    color="white !important">{`${seasonStatistics.matchesCompleted} de ${seasonStatistics.totalMatches}`}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{display: 'flex', alignItems: 'center', paddingLeft: "10px"}}>
                        <Typography variant="body2" color="white !important">Rodadas</Typography>
                        <Box sx={{width: '100%', mr: 1, paddingRight: "10px", paddingLeft: "10px"}}>
                            <LinearProgress className="championship-progress-bar" color="success" variant="determinate"
                                            value={normalise(seasonStatistics.game_week, seasonStatistics.total_game_week)}/>
                        </Box>
                        <Box sx={{whiteSpace: 'nowrap', paddingRight: "10px"}}>
                            <Typography variant="body2"
                                        color="white !important">{`${seasonStatistics.game_week} de ${seasonStatistics.total_game_week}`}</Typography>
                        </Box>
                    </Box>
                </Box>
                {/*<Grid container xs={12} lg={12}>*/}
                {/*  <Grid container xs={1} lg={1}>*/}
                {/*    <Typography>Rodada</Typography>*/}
                {/*    <Chip label="Chip Filled"/>*/}
                {/*  </Grid>*/}
                {/*  <Grid container xs={1} lg={1}>*/}
                {/*    <Typography>Jogos<Avatar className="team-flag" src={`${principalData.image}`}*/}
                {/*                             variant="square"/></Typography>*/}
                {/*    <Chip label={`${principalData.seasonMatchesPlayed_overall}`}/>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
            </Box>
            <TabPanel value={value} index={0}>
                <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                    <Grid container item xs={12} lg={6}>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"gols_pro_ht"}
                                column={gols_pro_ht_columns}
                                title="Gols Pró HT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"gols_contra_ht"}
                                column={gols_contra_ht_columns}
                                title="Gols Contra HT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"btts_ht"}
                                column={btts_ht_columns}
                                title="BTTS HT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"cantos_ht"}
                                column={cantos_ht_columns}
                                title="Cantos HT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <TableMinutes
                                data={principalData.minutagem_ht}
                                flag={principalData.image}
                                column={gols_minutagem_ht_columns}
                                title="Minutagem de Gols Mandante"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <TableMinutes
                                data={visitorData.minutagem_ht}
                                flag={visitorData.image}
                                column={gols_minutagem_ht_columns}
                                title="Minutagem de Gols Visitante"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <EnhancedChanceTable
                                rows={chance_ht}
                                headCells={chance_ht_columns}
                                title={chance_title_ht}
                                pflag={principalData.image}
                                vflag={visitorData.image}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <EnhancedPoissonTable
                                rows={poisson_ht}
                                headCells={poisson_ht_columns}
                                title={title_ht}
                                title_alt=""
                                pflag={principalData.image}
                                vflag={visitorData.image}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MatchesTable
                                rows={principalData.confrontos}
                                headCells={matches_ft_columns}
                                title={matches_title_principal}
                                flag={principalData.image}
                                goodSide={"home"}
                                halftime={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MatchesTable
                                rows={visitorData.confrontos}
                                headCells={matches_ft_columns}
                                title={matches_title_visitor}
                                flag={visitorData.image}
                                goodSide={"away"}
                                halftime={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={2}>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"chutes_a_gol"}
                                column={chutes_a_gol}
                                title="Chutes"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"chutes_no_gol"}
                                column={chutes_no_gol}
                                title="Chutes no Gol"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                    <Grid container item xs={12} lg={6}>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"gols_pro_ft"}
                                column={gols_pro_ft_columns}
                                title="Gols Pró FT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"gols_contra_ft"}
                                column={gols_contra_ft_columns}
                                title="Gols Contra FT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"btts_ft"}
                                column={btts_ft_columns}
                                title="BTTS FT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"cantos_ft"}
                                column={cantos_ft_columns}
                                title="Cantos FT"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <TableMinutes
                                data={principalData.minutagem_ft}
                                flag={principalData.image}
                                column={gols_minutagem_ft_columns}
                                title="Minutagem de Gols Mandante"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <TableMinutes
                                data={visitorData.minutagem_ft}
                                flag={visitorData.image}
                                column={gols_minutagem_ft_columns}
                                title="Minutagem de Gols Visitante"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <EnhancedChanceTable
                                rows={chance_ft}
                                headCells={chance_ft_columns}
                                title={chance_title_ft}
                                pflag={principalData.image}
                                vflag={visitorData.image}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <EnhancedPoissonTable
                                rows={poisson_ft}
                                headCells={poisson_ft_columns}
                                title={title_ft}
                                title_alt=""
                                pflag={principalData.image}
                                vflag={visitorData.image}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MatchesTable
                                rows={principalData.confrontos}
                                headCells={matches_ft_columns}
                                title={matches_title_principal}
                                flag={principalData.image}
                                goodSide={"home"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MatchesTable
                                rows={visitorData.confrontos}
                                headCells={matches_ft_columns}
                                title={matches_title_visitor}
                                flag={visitorData.image}
                                goodSide={"away"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={2}>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"chutes_a_gol"}
                                column={chutes_a_gol}
                                title="Chutes"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <MatchTable
                                principal={principalData}
                                visitor={visitorData}
                                matchType={"chutes_no_gol"}
                                column={chutes_no_gol}
                                title="Chutes no Gol"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Tabs value={championshipTableValue} onChange={handleChampionshipTableChange} aria-label="match tab"
                      centered
                      textColor="secondary">
                    <Tab label="Mandante" {...a11yProps(0)} key="MandanteChampionshipTableValue"/>
                    <Tab label="Visitante" {...a11yProps(1)} key="VisitanteChampionshipTableValue"/>
                    <Tab label="Temporada" {...a11yProps(2)} key="TemporadaChampionshipTableValue"/>
                    {tables.length > 0 && tables.map((item, i) => {
                            if (!item.table) {
                                return <div></div>
                            }
                            return (
                                <Tab label={item.round} {...a11yProps(i + 3)} key={item.round + "ChampionshipTableValue"}/>
                            )
                        }
                    )}
                </Tabs>
                <TabPanel value={championshipTableValue} index={0}>
                    <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                        <Grid item xs={12} lg={12} xl={6}>
                            <TableClassification data={seasonTeams} column={classification_home_columns}
                                                 title={classification_title_mandante} defaultSort="points_home"/>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={championshipTableValue} index={1}>
                    <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                        <Grid item xs={12} lg={12} xl={6}>
                            <TableClassification data={seasonTeams} column={classification_away_columns}
                                                 title={classification_title_visitante} defaultSort="points_away"/>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={championshipTableValue} index={2}>
                    <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                        <Grid item xs={12} lg={12} xl={6}>
                            <TableClassification data={seasonTeams} column={classification_season_columns}
                                                 title={classification_title_temporada} defaultSort="points_overall"/>
                        </Grid>
                    </Grid>
                </TabPanel>
                {tables.length > 0 && tables.map((item, i) => {
                    if (!item.table) {
                        return <div></div>
                    }
                    return (
                        <TabPanel value={championshipTableValue} index={i + 3}>
                            <TableClassification data={item.table} column={classification_table_columns}
                                                 title={item.round} defaultSort="points"
                                                 zones={Object.values(item.zones)}/>
                        </TabPanel>
                    )
                })}
            </TabPanel>
            <TabPanel index={value} value={3}>
                <Box component="form">
                    <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                        <Grid container
                              style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Grid item>
                                <TextField size="small" id="odd-mandante" label="Odd Mandante" variant="filled"
                                           style={{margin: 10, width: '15ch'}} type="number"
                                           value={oddMandante}
                                           inputProps={{
                                               maxLength: 1000,
                                               max: 1000.0,
                                               step: 0.1,
                                               minLength: 1.0,
                                               min: 1.0,
                                           }}
                                           onChange={handleOddMandanteChange}
                                           className="odd-input"/>
                            </Grid>
                            <Grid item>
                                <TextField size="small" id="odd-visitante" label="Odd Visitante" variant="filled"
                                           style={{margin: 10, width: '15ch'}} type="number"
                                           value={oddVisitante}
                                           inputProps={{
                                               maxLength: 1000,
                                               max: 1000.0,
                                               step: 0.1,
                                               minLength: 1.0,
                                               min: 1.0,
                                           }}
                                           onChange={handleOddVisitanteChange}
                                           className="odd-input"/>
                            </Grid>
                            <Grid item>
                                <Chip label={tipificacaoJogo} color="error"/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} xl={6}>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={principalData.tipificacoes.seasons_1}
                                                   column={typification_columns}
                                                   title={principalData.name + " - Mandante Temporada Atual"}
                                                   highlight={tipificacaoJogo}
                                                   image={principalData.image} defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={visitorData.tipificacoes.seasons_1}
                                                   column={typification_columns}
                                                   title={visitorData.name + " - Visitante Temporada Atual"}
                                                   highlight={tipificacaoJogo}
                                                   image={visitorData.image}
                                                   defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={principalData.tipificacoes.seasons_1_total}
                                                   column={acumulated_typification_columns}
                                                   title="Acumulado - Temporada Atual"
                                                   highlight={tipificacaoJogo}
                                                   defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6} xl={6}>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={principalData.tipificacoes.seasons_4}
                                                   column={typification_columns}
                                                   title={principalData.name + " - Mandante 4 Temporadas"}
                                                   highlight={tipificacaoJogo}
                                                   image={principalData.image}
                                                   defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={visitorData.tipificacoes.seasons_4}
                                                   column={typification_columns}
                                                   title={visitorData.name + " - Visitante 4 Temporadas"}
                                                   highlight={tipificacaoJogo}
                                                   image={visitorData.image}
                                                   defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                                <TableTypification data={principalData.tipificacoes.seasons_4_total}
                                                   column={acumulated_typification_columns}
                                                   title="Acumulado - 4 Temporadas"
                                                   highlight={tipificacaoJogo}
                                                   defaultSort=""
                                                   className="table-typification"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>
            <TabPanel index={value} value={4}>
                <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                    <Grid container item xs={12} lg={12}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.sfm}
                                total={seasonScoreFrequency.qtd_jogos.sfm}
                                headCells={score_frequency_columns}
                                title="SFM"
                                title_alt="Super Favorito Mandante (Odd mínima 1,01 - Odd máxima 1,40)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.fm}
                                total={seasonScoreFrequency.qtd_jogos.fm}
                                headCells={score_frequency_columns}
                                title="FM"
                                title_alt="Favorito Mandante (Odd mínima 1,41 - Odd máxima 1,80)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.mfm}
                                total={seasonScoreFrequency.qtd_jogos.mfm}
                                headCells={score_frequency_columns}
                                title="MFM"
                                title_alt="Médio Favorito Mandante (Odd mínima 1,81 - Odd máxima 2,20)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.eq}
                                total={seasonScoreFrequency.qtd_jogos.eq}
                                headCells={score_frequency_columns}
                                title="EQ"
                                title_alt="Equilibrado (Odd mínima 2,21)"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={12}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.sfv}
                                total={seasonScoreFrequency.qtd_jogos.sfv}
                                headCells={score_frequency_columns}
                                title="SFV"
                                title_alt="Super Favorito Visitante (Odd mínima 1,01 - Odd máxima 1,40)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.fv}
                                total={seasonScoreFrequency.qtd_jogos.fv}
                                headCells={score_frequency_columns}
                                title="FV"
                                title_alt="Favorito Visitante (Odd mínima 1,41 - Odd máxima 1,80)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.mfv}
                                total={seasonScoreFrequency.qtd_jogos.mfv}
                                headCells={score_frequency_columns}
                                title="MFV"
                                title_alt="Medio Favorito Visitante (Odd mínima 1,81 - Odd máxima 2,20)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <EnhancedPoissonTable
                                rows={seasonScoreFrequency.total}
                                total={seasonScoreFrequency.qtd_jogos.total}
                                headCells={score_frequency_columns}
                                title="TOTAL"
                                title_alt="Acumulado Total"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel index={value} value={5}>
                <Grid container columnSpacing={{xs: 0.1, sm: 0.1, md: 0.1}}>
                    <Grid container
                          style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Grid item>
                            <TextField size="small" id="odd-mandante" label="Odd Mandante" variant="filled"
                                       style={{margin: 10, width: '15ch'}} type="number"
                                       value={oddMandante}
                                       inputProps={{
                                           maxLength: 1000,
                                           max: 1000.0,
                                           step: 0.1,
                                           minLength: 1.0,
                                           min: 1.0,
                                       }}
                                       onChange={handleOddMandanteChange}
                                       className="odd-input"/>
                        </Grid>
                        <Grid item>
                            <TextField size="small" id="odd-visitante" label="Odd Visitante" variant="filled"
                                       style={{margin: 10, width: '15ch'}} type="number"
                                       value={oddVisitante}
                                       inputProps={{
                                           maxLength: 1000,
                                           max: 1000.0,
                                           step: 0.1,
                                           minLength: 1.0,
                                           min: 1.0,
                                       }}
                                       onChange={handleOddVisitanteChange}
                                       className="odd-input"/>
                        </Grid>
                        <Grid item>
                            <Chip label={tipificacaoJogo} color="error"/>
                        </Grid>
                    </Grid>
                </Grid>
                <ResponsiveReactGridLayout
                    layouts={layout}
                    breakpoints={{lg: 1920, md: 1280, sm: 768, xs: 0}}
                    cols={{lg: 96, md: 50, sm: 38, xs: 24}}
                    rowHeight={20}
                    onLayoutChange={onLayoutChange}
                    draggableHandle={isEditing ? undefined : ".not-existing-class"}
                >
                    <div key='market-tipificacao'>
                        <MarketTipificacaoTable
                            data={{
                                mandante: principalData.tipificacoes,
                                visitante: visitorData.tipificacoes,
                                acumulado: principalData.tipificacoes.seasons_1_total,
                            }}
                            title="Tipificacao"
                            highlight={tipificacaoJogo}
                            className="tipificacao-table"
                        />
                    </div>
                    <div key="market-team-statistics">
                        <MarketTeamStatisticsTable
                            rows={[
                                {
                                    id: 'Mandante', ...principalData,
                                    chance_gols: market.chance_gols.mandante,
                                    chance_gols_total: market.chance_gols.total
                                },
                                {
                                    id: 'Visitante', ...visitorData,
                                    chance_gols: market.chance_gols.visitante,
                                    chance_gols_total: market.chance_gols.total
                                }]}
                            onCellEditCommit={handleTeamStatisticsCommited}/>
                    </div>

                    <div key="market-btts">
                        <MarketBttsTable rows={market.btts_ft}
                                         onCellEditCommit={handleBttsCommited}/>
                    </div>
                    <div key="market-match-odds">
                        <MarketMatchOddsTable rows={market.match_odds_ft}/>
                    </div>
                    <div key="market-over">
                        <MarketOverTable rows={market.over_under_ft}
                                         onCellEditCommit={handleEditOverUnderCommited}/>
                    </div>
                    <div key="market-under">
                        <MarketUnderTable rows={market.over_under_ft}
                                          onCellEditCommit={handleEditOverUnderCommited}/>
                    </div>
                    <div key="market-handcap-asiatico">
                        <MarketHandcapAsiaticoTable rows={market.handcap_asiatico}
                                                    onCellEditCommit={handleHandcapCommited}/>
                    </div>
                    <div key="market-poisson">
                        <EnhancedPoissonTable
                            rows={market.poisson_ft}
                            headCells={poisson_ft_columns}
                            title={title_ft}
                            title_alt=""
                            pflag={principalData.image}
                            vflag={visitorData.image}
                        />
                    </div>
                    <div key="market-score-frequency">
                        <EnhancedPoissonTable
                            rows={seasonScoreFrequency[tipificacaoJogo.toLowerCase() === 'indefinido' ? 'total' : tipificacaoJogo.toLowerCase()]}
                            total={seasonScoreFrequency.qtd_jogos[tipificacaoJogo.toLowerCase() === 'indefinido' ? 'total' : tipificacaoJogo.toLowerCase()]}
                            headCells={score_frequency_columns}
                            title={tipificacaoJogo.toLowerCase() === 'indefinido' ? 'TOTAL' : tipificacaoJogo}
                            title_alt=""
                        />
                    </div>
                    <div key="market-chutes-a-gol">
                        <MatchTable
                            principal={principalData}
                            visitor={visitorData}
                            matchType={"chutes_a_gol"}
                            column={chutes_a_gol}
                            title="Chutes"
                        />
                    </div>
                    <div key="market-chutes-do-gol">
                        <MatchTable
                            principal={principalData}
                            visitor={visitorData}
                            matchType={"chutes_no_gol"}
                            column={chutes_no_gol}
                            title="Chutes no Gol"
                        />
                    </div>
                    <div key="market-cantos-ft">
                        <MatchTable
                            principal={principalData}
                            visitor={visitorData}
                            matchType={"cantos_ft"}
                            column={cantos_ft_columns}
                            title="Cantos FT"
                        />
                    </div>
                </ResponsiveReactGridLayout>
            </TabPanel>
        </Box>
    );
}