import {
  Avatar,
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";

var columnMaxValues = {}
export const TableMinutes = ({data, column, title, flag}) => {
  columnMaxValues = column.map((item) => {
    const heading = item.heading
    var maxValues = {};
    maxValues[heading] = {};
    const maxMaxValuesMarcados = Math.max(...Object.values(data).map(item => item.marcados));
    maxValues[heading]["marcados"] = maxMaxValuesMarcados;
    const maxMaxValuesSofridos = Math.max(...Object.values(data).map(item => item.sofridos));
    maxValues[heading]['sofridos'] = maxMaxValuesSofridos;
    return maxValues
  })

  return (
    <TableContainer>
      <Table size="small" className="minutes-table">
        <TableHead key="Titulo">
          <TableRow>
            <TableCell
              className="table-title"
              colSpan="5"
              align={"center"}
              sx={{fontSize: "0.8rem", padding: "2px"}}
            >
              {/*<CardMedia*/}
              {/*  sx={{height: 25, width: 25}}*/}
              {/*  image={`${flag}`}*/}
              {/*  title="green iguana"*/}
              {/*/>*/}
              <Avatar className="team-flag" src={`${flag}`} variant="square"/>
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        {column.map((item, index) => (
          <TableRowMinutes key={index + title} item={item} index={index} data={data}/>
        ))}
      </Table>
    </TableContainer>
  );
};

const TableRowMinutes = ({item, index, data}) => (
  <TableBody className={"table-body"}
             data-separator={handleSeparator(item.minutes)}>
    <TableRow className="line-color" hover>
      <TableCell
        rowSpan="2"
        className="match-status"
        align={"center"}
        key={index + 1}
        sx={{fontSize: "0.8rem", padding: "2px"}}
        data-status={handleStyle(item.minutes, data)}
      >
        {item.heading}
      </TableCell>
      <TableCell
        align={"center"}
        key={index + 2}
        sx={{fontSize: "0.8rem", padding: "2px"}}
      >
        {item.marcados.value}
      </TableCell>
      <TableCell className="minutes-table-cell">
        {data[`${item.minutes}`][`${item.marcados.value}`]}
      </TableCell>
      <TableCell className="minutes-table-cell">
        <Box width="100%" mr={1}>
          <LinearProgress className="minutes-progress-bar" sx={{width: "4.0rem"}}
                          color="success" variant="determinate"
                          value={normalise(data[`${item.minutes}`][`${item.marcados.value}`], item.heading, "marcados")}/>
        </Box>
      </TableCell>
      <TableCell className="minutes-table-cell">
        {addMask(data[`${item.minutes}`][`${item.marcados_chance.value}`], item.marcados_chance)}
      </TableCell>
    </TableRow>
    <TableRow className="line-color" hover>
      <TableCell
        align={"center"}
        key={index + 3}
        sx={{fontSize: "0.8rem", padding: "2px"}}
      >
        {item.sofridos.value}
      </TableCell>
      <TableCell className="minutes-table-cell">
        {data[`${item.minutes}`][`${item.sofridos.value}`]}
      </TableCell>
      <TableCell className="minutes-table-cell">
        <Box width="100%" mr={1}>
          <LinearProgress className="minutes-progress-bar" sx={{width: "4.0rem"}}
                          color="error" variant="determinate"
                          value={normalise(data[`${item.minutes}`][`${item.sofridos.value}`], item.heading, "sofridos")}/>
        </Box>
      </TableCell>
      <TableCell className="minutes-table-cell">
        {addMask(data[`${item.minutes}`][`${item.sofridos_chance.value}`], item.sofridos_chance)}
      </TableCell>
    </TableRow>
  </TableBody>
);

function handleStyle(value, columnItem) {
  let gf = columnItem[value]['marcados']
  let gs = columnItem[value]['sofridos']

  if (gf > gs) {
    return "good"
  }

  if (gs > gf) {
    return "bad"
  }

  return "neutral"
}

function handleSeparator(value) {
  if (["0-15", "46-60", "0-45"].includes(value)) {
    return "top-block"
  }

  return ""
}

function addMask(value, columnItem) {
  if (['prob'].includes(columnItem.tipo)) {
    return value.toFixed(2) + '%'
  }

  return value
}

const MIN = 0
const normalise = (value, heading, subheading) => {
  const MAX = (columnMaxValues.find(item => heading in item) || {})[heading][subheading]
  return (value - MIN) * 100 / (MAX - MIN);
}