import React from 'react';

import {Box, Container, Typography} from "@mui/material";

function Term() {
    const style = {
        bgcolor: 'lightgray',
        p: 4,
        borderRadius: '20px',
    };
    return (
        <Container component="main" maxWidth="sm" className="term">
            <Box sx={style}>
                <Typography variant="subtitle2" className="chapter">
                    Agradecemos por utilizar nosso software, ao usá-lo, você está concordando com estes termos. Leia-os
                    com
                    atenção.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O Prematch é um software que oferece informações e estatísticas sobre os principais campeonatos
                    do mundo relacionado ao esporte futebol que pode ser utilizado como ferramenta de auxilio para
                    operações no
                    mercado de investimento esportivo. O acesso ao Prematch representa a aceitação expressa e irrestrita
                    dos
                    termos de uso abaixo descritos.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O desenvolvedor não se responsabiliza direta ou indiretamente pela utilização de qualquer um dos
                    dados
                    ou estatísticas nele contidos. Estes dados e estatísticas são baseadas em estudos matemáticos e
                    experiências
                    pessoais e profissionais deste desenvolvedor. O objetivo do Prematch é agilizar a coleta de dados
                    estatísticos
                    para estudos de padrões matemáticos das equipes de futebol. Não garantimos lucros e não fazemos
                    operações para
                    você. Cada usuário é o único responsável por suas perdas e ganhos.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Nenhum conteúdo deste software pode ser interpretado como sugestão de compra ou de venda de posições
                    no
                    mercado, assim como não podemos garantir nenhum retorno financeiro sobre qualquer operação feita
                    através das
                    informações que fornecemos, sejam elas pagas ou gratuitas.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O Prematch empenha-se em manter a qualidade, atualidade e autenticidade das informações do software,
                    mas seu
                    criador e os seus colaboradores não se responsabilizam por eventuais falhas nos serviços ou
                    inexatidão das
                    informações oferecidas. O usuário não deve ter como pressuposto que tais serviços e informações são
                    isentos de
                    erros ou serão adequados aos seus objetivos particulares. O criador e os seus colaboradores tampouco
                    assumem o
                    compromisso de atualizar as informações, e reservam-se o direito de alterar as condições de uso a
                    qualquer
                    momento.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    Termos de Uso e Serviço
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O usuário (pessoa física ou jurídica), identificado na página de cadastramento do deste software,
                    deverá
                    aceitar o referido Termo de Uso e Serviço e todas as demais políticas e princípios que os regem.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    A aceitação deste Termo de Uso e Serviço é absolutamente indispensável a utilização Prematch, visto
                    que o
                    simples fato de fazer login e realizar consultas no sistema, já indica a sua aceitação irrevogável a
                    este
                    Termo de Uso.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    I – Do objeto
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Através dos dados e estatísticas, o Prematch mostra a você de forma resumida números quais as
                    equipes
                    produziram ao longo de sua jornada no campeonato selecionado.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    II – Do Acordo de Licença
                </Typography>
                <Typography variant="body2" className="paragraph">
                    A licença concebida ao Prematch, é exclusiva, intransferível e limitada ao seu período de acesso.
                    Você é
                    proibido de fazer download, armazenar, transmitir, mostrar, copiar ou distribui o material
                    disponibilizado.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    III –Do Acesso ao Prematch
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O visitante poderá usar o Prematch apenas para finalidades lícitas.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Se a equipe mantenedora do software acreditar que os dados estão sendo compartilhados,
                    comercializados ou
                    distribuidos, poderá suspender o acesso ao Prematch a qualquer momento e sem qualquer notificação
                    prévia.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    A Política de Privacidade deste software Prematch é parte este Termo de Uso e Serviço.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O Tempo de acesso ao sistema vai variar de acordo com o plano assinado.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    IV – Erros e Falhas
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Os documentos, informações, imagens e gráficos publicados neste software Prematch podem conter
                    imprecisões
                    técnicas ou erros tipográficos. Em nenhuma hipótese o criador do sistema ou seus respectivos
                    colaboradores
                    serão responsáveis por qualquer dano direto ou indireto decorrente da impossibilidade de uso, perda
                    de dados
                    ou lucros, resultante do acesso e desempenho do site, dos serviços oferecidos ou de informações
                    disponíveis
                    neste site.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    V – Das Garantias Limitadas
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Não garantimos que nosso software será interrupto ou livre de erros. Trabalharemos em defesa do
                    perfeito
                    funcionamento do mesmo.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    VI – Das Responsabilidades Limitadas
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O criador do software Prematch e equipe não são responsáveis por qualquer perda ou dano de qualquer
                    tipo
                    ocasionado por erro ou omissão proveniente dos serviços online, indisponibilidade ou interrupção dos
                    serviços
                    online, materiais e conteúdo apresentados no sistema.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    Direitos Autorais e Propriedade Intelectual
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Os documentos, conteúdos e criações contidos neste software Prematch pertencem aos seus criadores e
                    colaboradores. A autoria do conteúdo, material e imagens exibidos no software Prematch é protegida
                    por leis
                    nacionais e internacionais. Não podem ser copiados, reproduzidos, modificados, publicados,
                    atualizados,
                    postados, transmitidos ou distribuídos de qualquer maneira sem autorização prévia e por escrito do
                    autor.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    As imagens contidas neste software Prematch são aqui incorporadas apenas para fins de visualização,
                    e, salvo
                    autorização expressa por escrito, não podem ser gravadas ou baixadas via download. A reprodução ou
                    armazenamento de materiais recuperados a partir deste serviço sujeitará os infratores às penas da
                    lei.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    O nome do software Prematch, seu logotipo, o nome de domínio para acesso na Internet, bem como todos
                    os
                    elementos característicos da tecnologia desenvolvida e aqui apresentada, sob a forma da articulação
                    de bases
                    de dados, constitui marcas registradas e propriedades intelectuais privadas e todos os direitos
                    decorrentes de
                    seu registro são assegurados por lei. Alguns direitos de uso podem ser cedidos por autorização em
                    contrato ou
                    licença especial, que pode ser cancelada a qualquer momento se não cumpridos os seus termos.
                </Typography>
                <Typography variant="subtitle2" className="chapter">
                    Sobre estes Termos
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Podemos modificar estes termos ou quaisquer termos adicionais que sejam aplicáveis a um Serviço
                    para, por
                    exemplo, refletir alterações da lei ou mudanças em nossos Serviços. Você deve consultar os termos
                    regularmente. Postaremos avisos sobre modificações nesses termos no software Prematch. Se você não
                    concordar
                    com os termos alterados de um Serviço ou Treinamento, deve descontinuar o uso desse Serviço ou
                    Treinamento.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Em caso de conflito entre estes termos e os termos adicionais, os termos adicionais prevalecerão com
                    relação a
                    esse conflito.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Estes termos regem a relação entre o software Prematch e você. Eles não criam quaisquer direitos
                    para
                    terceiros.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Caso você não cumpra estes termos e nós não tomemos providências imediatas, isso não significa que
                    estamos
                    renunciando a quaisquer direitos que possamos ter (como tomar providências futuras).
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Proibido aliciar pessoas e outros integrantes dos grupos ou da comunidade do software Prematch para
                    participar
                    de outros cursos, grupos ou canais sem a devida autorização do software Prematch , sob pena de
                    exclusão de sua
                    participação definitiva dos sistema sem o direito de ressarcimento do valor pago pelo acesso.
                </Typography>
                <Typography variant="body2" className="paragraph">
                    Contato com o suporte para esclarecimento de
                    dúvidas.
                </Typography>
                <Typography variant="body2" className="sign">
                    Atenciosamente
                </Typography>
                <Typography variant="body2" className="sign">
                    Murilo Mussumago
                </Typography>
                <Typography variant="body2" className="sign">
                    CEO – Prematch
                </Typography>
            </Box>
        </Container>
    )
}

export default Term;
