import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import * as React from "react";

export const MarketTeamStatisticsTable = (props) => {

    const teamStatisticsColumns = [
        {
            field: 'id',
            headerName: 'Confronto',
            description: '',
            width: 130,
            sortable: false,
        },
        {
            field: 'jogos',
            headerName: "Jogos",
            description: 'Total de jogos como Mandante ou Visitante.',
            width: 80,
            sortable: false,
        },
        {
            field: 'gols-pro',
            headerName: "GP",
            description: 'Gols feitos',
            width: 40,
            editable: true,
            sortable: false,
            valueGetter: (params) => params.row.gols_pro_ft.qtd
        },
        {
            field: 'media-gols_pro',
            headerName: "MGP",
            description: 'Média de gols feitos por jogo',
            width: 40,
            sortable: false,
            valueGetter: (params) => params.row.gols_pro_ft.media_time
        },
        {
            field: 'gols-contra',
            headerName: "GC",
            description: 'Gols sofridos',
            width: 40,
            editable: true,
            sortable: false,
            valueGetter: (params) => params.row.gols_contra_ft.qtd
        },
        {
            field: 'media-gols_contra',
            headerName: "MGC",
            description: 'Média de gols sofridos por jogo',
            width: 40,
            sortable: false,
            valueGetter: (params) => params.row.gols_contra_ft.media_time
        },
        {
            field: 'media-geral',
            headerName: "Média Liga",
            description: 'Média do campeonato de gols feitos',
            width: 90,
            sortable: false,
            valueGetter: (params) => params.row.gols_pro_ft.media_geral
        },
        {field: "chance_gols", headerName: "ProbG", width: 60, description: "Probabilidade Gols"},
        {
            field: "chance_gols_total",
            headerName: "Prob Total",
            width: 80,
            description: "Soma das probabilidades de gol",
            sortable: false
        },
    ]

    const {rows} = props

    return (
        <div className="datagrid-container">
            <DataGrid
                className="market-team-statistics-table overUnder"
                getRowId={(row) => row.id}
                rows={rows}
                columns={teamStatisticsColumns}
                pageSize={25}
                autoHeight
                rowHeight={25}
                headerHeight={25}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                onCellEditCommit={props.onCellEditCommit}
            />
        </div>
    )
}

MarketTeamStatisticsTable.propTypes = {
    rows: PropTypes.array.isRequired,
    onCellEditCommit: PropTypes.func.isRequired,
};