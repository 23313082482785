import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Avatar} from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function handleStyle(value, columnItem) {
  if (columnItem === "goals-principal") {
    return "principal-color";
  }

  if (columnItem === "goals-visitor") {
    return "visitor-color";
  }

  if (columnItem === "visitor" || columnItem === "principal") {
    // if (value > 60) {
    //   return "perfect";
    // } else if (value > 50) {
    //   return "awesome";
    // } else if (value > 40) {
    //   return "verygood";
    // } else if (value > 30) {
    //   return "good";
    // } else if (value > 20) {
    //   return "neutralgood";
    // } else if (value > 10) {
    //   return "neutral";
    // } else if (value > 0.1) {
    //   return "almostbad";
    // } else {
    //   return "bad";
    // }


    if (value > 30.0) {
      return "level10";
    } else if (value > 7.0) {
      return "level9";
    } else if (value > 6.0) {
      return "level8";
    } else if (value > 5.0) {
      return "level7";
    } else if (value > 3.0) {
      return "level6";
    } else if (value > 1.4) {
      return "level5";
    } else if (value > 0.9) {
      return "level4";
    } else if (value > 0.4) {
      return "level3";
    } else if (value > 0.1) {
      return "level2";
    } else {
      return "level1";
    }
  }
}

function addMask(value, columnItem) {
  if (['prob'].includes(columnItem)) {
    return value.toFixed(2) + '%'
  }

  return value
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {order, orderBy, onRequestSort, headCells} =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow className="table-title">
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="enhaced-table-title"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {title, pflag, vflag} = props;
  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        fontSize: "0.8rem", padding: "2px",
      }}
      className="enhaced-chance-toolbar"
      colSpan="3"
      align={"center"}
    >
      <Typography
        sx={{
          flex: '1 1 100%',
          fontSize: "0.8rem", padding: "2px",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="table-title enhaced-table-title"
      >
        <Avatar className="team-flag" src={`${pflag}`} variant="square"/>
        {title}
        <Avatar className="team-flag flag-visitante" src={`${vflag}`} variant="square"/>
      </Typography>

      {/*<Tooltip title="Filter list">*/}
      {/*  <IconButton sx={{padding: "2px"}}>*/}
      {/*    <FilterListIcon/>*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  vflag: PropTypes.string.isRequired,
  pflag: PropTypes.string.isRequired,
};

export const EnhancedChanceTable = ({rows, headCells, title, vflag, pflag}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows],
  );

  return (
    <Box sx={{width: '100%', maxWidth: '270px', margin: '0px 0px 10px'}}>
      <Paper sx={{width: '100%', mb: 2, height: '100%'}}>
        <EnhancedTableToolbar title={title} vflag={vflag} pflag={pflag}/>
        <TableContainer>
          <Table
            sx={{margin: '0px'}}
            aria-labelledby="tableTitle"
            size={'small'}
            className="enhanced-chance-table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    className="match-table-row"
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none" align={"center"}
                      className={"chance-status"} sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                      data-status={handleStyle(row.goals, "goals-principal")}
                    >
                      {row.goals}
                    </TableCell>
                    <TableCell align={"center"}
                               className={"chance-status"} sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                               data-status={handleStyle(row.principal, "principal")}>{addMask(row.principal, "prob")}</TableCell>
                    <TableCell align={"center"}
                               className={"chance-status"} sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                               data-status={handleStyle(row.visitor, "visitor")}>{addMask(row.visitor, "prob")}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none" align={"center"}
                      className={"chance-status"} sx={{fontSize: "0.8rem", border: "none", padding: "2px"}}
                      data-status={handleStyle(row.goals, "goals-visitor")}
                    >
                      {row.goals}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (33) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}