import {Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";

export const MatchTable = ({
                             principal,
                             visitor,
                             title,
                             matchType,
                             column,
                           }) => {
  return (
    <TableContainer>
      <Table
        padding={"normal"}
        size="small"
        className="match-table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              className="table-title"
              sx={{fontSize: "0.8rem", padding: "2px"}}
              align={"center"}
              colSpan="4"
            >
              <Avatar className="team-flag" src={`${principal.image}`} variant="square"/>
              {title}
              <Avatar className="team-flag flag-visitante" src={`${visitor.image}`} variant="square"/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead key="4">
          <TableRow className="match-table-row">
            <TableCell
              className="match-principal"
              sx={{fontSize: "0.8rem", padding: "2px"}}
              colSpan="2"
              align={"center"}
            >
              {principal.name}
            </TableCell>
            <TableCell
              className="match-visitor"
              sx={{fontSize: "0.8rem", padding: "2px"}}
              colSpan="2"
              align={"center"}
            >
              {visitor.name}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {column.map((item, index) => (
            <TableHeadItem
              key={index}
              item={item}
              principal={principal[`${matchType}`]}
              visitor={visitor[`${matchType}`]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function handleStyle(value, item, mediaGeral) {
  const itemValue = item.value
  if (
    itemValue === "ranking_time" ||
    itemValue === "ranking" ||
    itemValue === "ranking_var" ||
    itemValue === "ranking_linear" ||
    itemValue === "ranking_media"
  ) {
    if (value < 7) {
      return "good";
    } else if (value < 14) {
      return "neutral";
    }
    return "bad";
  }

  if (
    itemValue === "media_geral" ||
    itemValue === "liga" || itemValue === "media_liga" || itemValue === "media_cantos_liga_total") {
    return "grey"
  }

  if (itemValue === "media_var_time" || itemValue === "media_var_geral") {
    if (value < 0.6) {
      return "good";
    } else if (value < 1.0) {
      return "neutral"
    }
    return "bad";
  }

  if (
    itemValue === "media" ||
    itemValue === "media_chutes" ||
    itemValue === "media_cantos_pro" ||
    itemValue === "media_cantos_contra" ||
    itemValue === "media_cantos_total"
  ) {
    return "neutral";
  }

  if (itemValue === "media_cantos_partida") {
    return "purple"
  }

  if (
    itemValue === 'media_time'
  ) {
    if (value < mediaGeral) {
      return item.heading.includes('GP') ? 'bad' : 'good';
    } else if (value > mediaGeral + 0.1) {
      return item.heading.includes('GP') ? 'good' : 'bad';
    }
    return 'neutral'
  }

  if (itemValue === "casa") {
    if (value > mediaGeral + 5) {
      return "good";
    } else if (value < mediaGeral - 5) {
      return "bad"
    }
    return "neutral"
  }

  if (
    itemValue === "linear" ||
    itemValue === "chutes" ||
    itemValue === "cantos_pro" ||
    itemValue === "cantos_contra"
  ) {
    return "blue";
  }
}

function addMask(value, item) {
  if (typeof value === "undefined" || value === null) {
    return value
  }
  const itemValue = item.value

  if (item.tipo === 'btts') {
    return value.toFixed(2) + '%'
  }

  if (['chutes', 'cantos_pro', 'cantos_contra'].includes(itemValue)) {
    return value
  }
  return value.toFixed(2)

}

const TableHeadItem = ({item, principal, visitor}) => (
  <TableRow className="match-table-row">
    <TableCell
      className="match-principal"
      sx={{fontSize: "0.8rem", padding: "2px"}}
      align={"right"}
    >
      {item.heading}
    </TableCell>
    <TableCell
      className={"match-status"}
      data-status={handleStyle(principal[`${item.value}`], item, principal['media_geral'])}
      sx={{fontSize: "0.8rem", padding: "2px"}}
    >
      {`${item.value}` === "media_cantos_partida" ?
        addMask((principal['media_cantos_total'] + visitor['media_cantos_total']) / 2, item) :
        addMask(principal[`${item.value}`], item)}
    </TableCell>
    <TableCell
      className={"match-status"}
      data-status={handleStyle(visitor[`${item.value}`], item, visitor['media_geral'])}
      sx={{
        fontSize: "0.8rem",
        padding: "2px",
      }}
      align={"right"}
    >
      {`${item.value}` === "media_cantos_partida" ?
        addMask((principal['media_cantos_total'] + visitor['media_cantos_total']) / 2, item) :
        addMask(visitor[`${item.value}`], item)}
    </TableCell>
    <TableCell className="match-visitor" sx={{fontSize: "0.8rem", padding: "2px"}}>
      {item.heading === 'Casa' ? 'Fora' : item.heading}
    </TableCell>
  </TableRow>
);
