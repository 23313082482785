import React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import {Monthly} from "./Monthly";
import {Quarterly} from "./Quarterly";
import {Semiannual} from "./Semiannual";
import {Annual} from "./Annual";

function Subscription() {
    const style = {
        bgcolor: 'lightgray',
        p: 2,
    };

    const titleStyle = {
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        ...style,
    };

    const planStyle = {
        minHeight: '100%',
        marginTop: '16px',
        p: 2,
        ...style,
    };

    return (
        <Container component="main" maxWidth="lg" className="term">
            <Grid sx={titleStyle}>
                <Typography component="h1" variant="h5" align="left"
                            style={{fontWeight: '500', textAlign: 'center'}}>
                    Planos de Assinatura
                </Typography>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="stretch">
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Monthly sx={{...planStyle, borderBottomLeftRadius: '10px'}} price='67,00'/>
                    {/*</Box>*/}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Quarterly sx={{...planStyle}} price='180,00' monthly='60,00'
                               discount="10"/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Semiannual sx={{...planStyle}} price='312,00' monthly='52,00'
                                discount="22"/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Annual sx={{...planStyle, borderBottomRightRadius: '10px'}} price='516,00' monthly='43,00'
                            discount="35"/>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Subscription;
