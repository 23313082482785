import * as React from "react";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";

export const MarketBttsTable = (props) => {

    const bttsColumns = [
        {
            field: 'prob', headerName: "BTTS Sim", width: 90, sortable: false,
            renderCell: (params) => (params.value + "%")
        },
        {field: 'fair', headerName: "Fair", width: 90, sortable: false,},
        {field: 'odd', headerName: "Odd", width: 90, editable: true, sortable: false,},
        {
            field: 'valor', headerName: "Valor", width: 90, sortable: false, cellClassName: (params) =>
                params.value > 10 ? "cell-green" : params.value > 0 ? 'cell-yellow' : 'cell-red',
            renderCell: (params) => (params.value + "%")
        }
    ]


    const {rows} = props
    return (
        <div className="datagrid-container">
            <DataGrid
                disableColumnMenu
                style={{width: '360px'}}
                className="overUnder"
                getRowId={(row) => row.id}
                rows={rows}
                columns={bttsColumns}
                pageSize={25}
                autoHeight
                rowHeight={25}
                headerHeight={25}
                rowsPerPageOptions={[]}
                hideFooterPagination={true}
                hideFooter={true}
                onCellEditCommit={props.onCellEditCommit}
            />
        </div>
    )
}

MarketBttsTable.propTypes = {
    rows: PropTypes.array.isRequired,
    onCellEditCommit: PropTypes.func.isRequired,
};