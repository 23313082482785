import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {store} from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {blueGrey, indigo, red} from "@mui/material/colors";
import posthog from "posthog-js";
import {PostHogProvider} from "posthog-js/react";

const container = document.getElementById("root");
const root = createRoot(container);

const theme = createTheme({
    palette: {
        primary: indigo,
        secondary: blueGrey,
    },
    action: {
        active: red[200],
        activeOpacity: 1,
        hover: red[100],
        hoverOpacity: 0.7,
        focus: red[600],
        focusOpacity: 1,
        selected: red[300],
        selectedOpacity: 1
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1500,
            xl: 2500
        }
    },
    components: {
        // MuiButtonBase: {
        //     styleOverrides: {
        //         root: {
        //             "&:hover": {
        //                 backgroundColor: pink[100],
        //                 color: pink[700]
        //             },
        //             "&.Mui-selected": {
        //                 backgroundColor: red[100],
        //                 color: red[700],
        //                 "&:hover": {
        //                     backgroundColor: green[100],
        //                     color: green[700]
        //                 }
        //             }
        //         },
        //     }
        // },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#9da6d6'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: "rgb(98, 113, 121)"
                },
                icon: {
                    color: "white"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: "white"
                },
                selectIcon: {
                    color: "white"
                }
            }
        },
        // MuiFormLabel: {
        //     styleOverrides: {
        //         root: {
        //             color: "rgb(98, 113, 121)"
        //         }
        //     }
        // },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             color: "rgb(98, 113, 121)"
        //         }
        //     }
        // },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "rgb(98, 113, 121)"
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    color: "rgb(98, 113, 121)"
                },
                root: {
                    "&.Mui-focused": {
                        "color": "rgb(98, 113, 121)"
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        // MuiSvgIcon: {
        //     styleOverrides: {
        //         root: {
        //             // color: "rgb(98, 113, 121)",
        //             "&:hover": {
        //                 // backgroundColor: pink[100],
        //                 color: pink[700]
        //             }
        //         }
        //     }
        // }
    }
})

posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
    {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        person_profiles: 'identified_only',
    }
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <PostHogProvider client={posthog}>
                <Provider store={store}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </Provider>
            </PostHogProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
