import PropTypes from "prop-types";
import {TableTypification} from "../common/TableTypification";
import * as React from "react";

export const MarketTipificacaoTable = props => {
    const acumulated_typification_columns = [
        {heading: "Ref", value: "ref", tipo: 'text', alt: "Referência ao time do confronto", id: 'ref', width: 150},
        {heading: "Tipo", value: "tipificacao", tipo: 'text', alt: "Nomenclatura do tipo de jogo", id: "tipificacao"},
        {heading: "Jogos", value: "jogos", tipo: 'int', alt: "Número de partidas", id: "jogos"},
        {
            heading: "% jogos",
            value: "porcentagem_jogos",
            tipo: '%',
            alt: "Porcentagem do tipo de jogo em relação ao total de partidas",
            id: "porcentagem_jogos"
        },
        {heading: "Pontos", value: "pontos", tipo: 'int', alt: "Pontos ganhos", id: "pontos"},
        {
            heading: "PPG",
            value: "pontos_por_jogo",
            tipo: 'float',
            alt: "Média de pontos ganhos por partida",
            id: "pontos_por_jogo"
        },
        {heading: "V", value: "vitorias", tipo: 'int', alt: "Número de vitórias", id: "vitorias"},
        {heading: "E", value: "empates", tipo: 'int', alt: "Número de empates", id: "empates"},
        {heading: "D", value: "derrotas", tipo: 'int', alt: "Número de derrotas", id: "derrotas"},
        {heading: "GP", value: "gols_pro", tipo: 'int', alt: "Número de gols a favor", id: "gols_pro"},
        {heading: "GC", value: "gols_contra", tipo: 'int', alt: "Número de gols contra", id: "gols_contra"},
        {heading: "MGP", value: "media_gols_pro", tipo: 'float', alt: "Média de gols a favor", id: "media_gols_pro"},
        {
            heading: "MGC",
            value: "media_gols_contra",
            tipo: 'float',
            alt: "Méida de gols contra",
            id: "media_gols_contra"
        },
        {
            heading: "CS",
            value: "cleansheet_porcentagem",
            tipo: '%',
            alt: "'CLean Sheet' - Porcentagem de partidas sem sofre gols",
            id: "cleansheet_porcentagem"
        },
        {
            heading: "FTS",
            value: "fts_porcentagem",
            tipo: '%',
            alt: "'Fail To Score' - Porcentagem de partidas sem marcar gols",
            id: "fts_porcentagem"
        },
        {
            heading: "BTTS",
            value: "btts_porcentagem",
            tipo: '%',
            alt: "'Both Teams To Score - Porcentagem de partidas em que as duas equipes marcaram gols",
            id: "btts_porcentagem"
        },
        {
            heading: "MCP",
            value: "media_cantos_a_favor",
            tipo: 'float',
            alt: "Médias de escanteios a favor",
            id: "media_cantos_a_favor"
        },
        {heading: "MCC", value: "media_cantos_contra", tipo: 'float', alt: "Méida de escanteios contra"},
        {
            heading: "MCT",
            value: "media_cantos_total",
            tipo: 'float',
            alt: "Média de escanteios totais (a favor e contra)",
            id: "media_cantos_total"
        },
        // {
        //     heading: "GPHT",
        //     value: "gols_pro_ht",
        //     tipo: 'number',
        //     alt: "Número de gols a favor no primeiro tempo",
        //     id: "gols_pro_ht"
        // },
        // {
        //     heading: "GCHT",
        //     value: "gols_contra_ht",
        //     tipo: 'number',
        //     alt: "Número de gols contra no primeiro tempo",
        //     id: "gols_contra_ht"
        // },
        // {
        //     heading: "MGPHT",
        //     value: "media_gols_pro_ht",
        //     tipo: 'float',
        //     alt: "Média de gols a favor no primeiro tempo",
        //     id: "media_gols_pro_ht"
        // },
        // {
        //     heading: "MGCHT",
        //     value: "media_gols_contra_ht",
        //     tipo: 'float',
        //     alt: "Média de gols contra no primeiro tempo",
        //     id: "media_gols_contra_ht"
        // },
    ]

    const {data, highlight, title, className} = props
    var dataFiltered = []
    if (highlight !== 'indefinido') {
        dataFiltered = [
            {ref: 'M', ...data.mandante.seasons_1.find(item => item.tipificacao === highlight)},
            {ref: 'V', ...data.visitante.seasons_1.find(item => item.tipificacao === highlight)},
            {
                ...data.acumulado.find(item => item.tipificacao === (highlight !== 'EQ' ? highlight : 'EQM')),
                ref: 'A'
            },
        ]
    }

    return (
        <TableTypification data={dataFiltered}
                           column={acumulated_typification_columns}
                           title={title}
                           defaultSort=""
                           className={className}
        />
    )
}

MarketTipificacaoTable.propTypes = {
    data: PropTypes.array.isRequired,
    highlight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
}